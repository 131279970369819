import { HandType } from "backend/src/common/types/roomState";
import classNames from "classnames";

import humanBg from "./human/bg.png";
import humanFg from "./human/fg.png";

import pocBg from "./poc/bg.png";
import pocFg from "./poc/fg.png";

import materialBg from "./material/bg.png";
import materialFg from "./material/fg.png";

import altushkaBg from "./altushka/bg.png";
import altushkaFg from "./altushka/fg.png";

import stickBg from "./stick/bg.png";
import stickFg from "./stick/fg.png";

import juliaBg from "./julia/bg.png";
import juliaFg from "./julia/fg.png";

import catBg from "./cat/bg.png";

import styles from "./styles.module.scss";
import { Card } from "components/Card/Card";

const HAND_IMAGES: Record<HandType, { fg?: string; bg: string }> = {
  [HandType.human]: {
    fg: humanFg,
    bg: humanBg,
  },
  [HandType.poc]: {
    fg: pocFg,
    bg: pocBg,
  },
  [HandType.material]: {
    fg: materialFg,
    bg: materialBg,
  },
  [HandType.stick]: {
    bg: stickBg,
    fg: stickFg,
  },
  [HandType.altushka]: {
    bg: altushkaBg,
    fg: altushkaFg,
  },
  [HandType.julia]: {
    bg: juliaBg,
    fg: juliaFg,
  },
  [HandType.cat]: {
    bg: catBg,
  },
};

type HandProps = {
  type: HandType;
  withCard?: boolean;
  left?: boolean;
};

const CARD_CONTENT = "Я карта";

export const Hand: React.FC<HandProps> = ({ type, left, withCard }) => {
  const handData = HAND_IMAGES[type];
  return (
    <div
      className={classNames(styles.hand, {
        [styles.left]: left,
        [styles.demo]: withCard,
      })}
    >
      {withCard && (
        <div className={styles.card}>
          <Card title="Карта?">
            <div className={styles.description}>
              {[
                CARD_CONTENT,
                ...new Array(10).fill(CARD_CONTENT.toLowerCase()),
              ].join(", ")}
            </div>
          </Card>
        </div>
      )}
      <div
        className={styles.bg}
        style={{
          backgroundImage: `url(${handData.bg})`,
        }}
      />
      {handData.fg && (
        <div
          className={styles.fg}
          style={{ backgroundImage: `url(${handData.fg})` }}
        />
      )}
    </div>
  );
};
