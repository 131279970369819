import { faShuffle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RoomState } from "backend/src/common/types/roomState";
import { useStoredState } from "hooks/useStoredState";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { Button, Form, Icon } from "react-bulma-components";
import { renameMe } from "socket/events/renameMe";
import { store } from "store/store";
import {
  uniqueNamesGenerator,
  Config,
  adjectives,
  animals,
} from "unique-names-generator";

const config: Config = {
  dictionaries: [adjectives, animals],
  separator: " ",
  style: "capital",
};

export const NameInput: React.FC<{
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
}> = observer(({ onKeyDown }) => {
  const [name, setName] = useStoredState<string>("lastName", "");
  const nameForPlaceholder = useMemo(() => uniqueNamesGenerator(config), []);

  const currentRoom = store.currentRoom;
  const isPending = store.roomState === RoomState.pending;
  useEffect(() => {
    if (currentRoom && isPending && name) {
      const timeout = setTimeout(() => {
        renameMe(name);
      }, 300);
      return () => clearTimeout(timeout);
    }
  }, [currentRoom, isPending, name]);

  return (
    <Form.Field>
      <Form.Label>Имя</Form.Label>
      <Form.Field kind="addons">
        <Form.Input
          placeholder={`Например, ${nameForPlaceholder}`}
          value={name}
          onChange={(e) => setName(e.target.value)}
          onKeyDown={onKeyDown}
        />
        <Form.Control>
          <Button
            color="primary"
            onClick={() => {
              setName(uniqueNamesGenerator(config));
            }}
          >
            <Icon>
              <FontAwesomeIcon icon={faShuffle} />
            </Icon>
          </Button>
        </Form.Control>
      </Form.Field>
    </Form.Field>
  );
});
