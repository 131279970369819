import { GameStage, RoomState } from "backend/src/common/types/roomState";
import { getOrCreateSocket } from "socket/socket";
import { store } from "store/store";

export const placeNewAnimal = (deckCardIndex: number) => {
  const socket = getOrCreateSocket();
  if (
    store.currentRoom &&
    store.roomState === RoomState.playing &&
    store.iAmActiveUser &&
    store.gameState.stage === GameStage.placing &&
    store.currentPlayer?.deck[deckCardIndex]
  ) {
    socket.emit("placeNewAnimal", store.currentRoom, deckCardIndex);
  }
};
