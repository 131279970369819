import { useStoredState } from "hooks/useStoredState";
import { joinRoom } from "socket/events/joinRoom";
import styles from "./styles.module.scss";
import { useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Container,
  Form,
  Icon,
  Menu,
  Section,
} from "react-bulma-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShuffle } from "@fortawesome/free-solid-svg-icons";
import { NameInput } from "components/NameInput/NameInput";
import { Header } from "components/Header/Header";
import { getRandomRoomName } from "utils/getRandomRoomName";
import { Background } from "./Background/Background";
import { Footer } from "components/Footer/Footer";
import { Page } from "components/Page/Page";
import { store } from "store/store";
import { observer } from "mobx-react-lite";

export const StartGame = observer(() => {
  const [roomName, setRoomName] = useStoredState<string>("roomNameInput", "");
  const [isPublic, setIsPublic] = useStoredState<boolean>("isPublic", true);
  useEffect(() => {
    if (window.location.hash) {
      setRoomName(window.location.hash.substring(1));
    }
  }, [setRoomName]);
  const randomRoomNameForPlaceholder = useMemo(() => getRandomRoomName(), []);

  const openRooms = store.openRooms;
  const hasOpenRooms = openRooms.length > 0;

  return (
    <>
      <div className={styles.mainContainer}>
        <Page>
          <Header centered />
          <Background />
          {hasOpenRooms && (
            <Section>
              <Container>
                <Box>
                  <Form.Field>
                    <Form.Label>Открытые комнаты</Form.Label>
                  </Form.Field>
                  <Menu>
                    {openRooms.map((room) => (
                      <Menu.List key={room.name}>
                        <Menu.List.Item
                          onClick={() => {
                            joinRoom(room.name);
                          }}
                          className={styles.openRoomItem}
                        >
                          <span>{room.name}</span>
                          <small>Игроков: {room.playersCount}</small>
                        </Menu.List.Item>
                      </Menu.List>
                    ))}
                  </Menu>
                </Box>
              </Container>
            </Section>
          )}
          <Section>
            <Container className={styles.container}>
              <Box>
                <Form.Field>
                  <Form.Label>Код комнаты</Form.Label>
                  <Form.Field kind="addons">
                    <Form.Input
                      autoFocus
                      placeholder={`Например, ${randomRoomNameForPlaceholder}`}
                      value={roomName}
                      onChange={(e) => setRoomName(e.target.value)}
                      onKeyDown={(event) => {
                        if (event.code === "Enter" && roomName) {
                          joinRoom(roomName, !!isPublic);
                        }
                      }}
                    />
                    <Form.Control>
                      <Button
                        color="primary"
                        onClick={() => {
                          const randomRoomName = getRandomRoomName();
                          setRoomName(randomRoomName);
                        }}
                      >
                        <Icon>
                          <FontAwesomeIcon icon={faShuffle} />
                        </Icon>
                      </Button>
                    </Form.Control>
                  </Form.Field>
                </Form.Field>
                <Form.Field>
                  <Form.Control>
                    <Form.Checkbox
                      checked={isPublic}
                      onChange={(e) => setIsPublic(e.target.checked)}
                    >
                      Публичная комната
                    </Form.Checkbox>
                  </Form.Control>
                </Form.Field>
                <NameInput
                  onKeyDown={(event) => {
                    if (event.code === "Enter" && roomName) {
                      joinRoom(roomName, !!isPublic);
                    }
                  }}
                />
                <Button
                  fullwidth
                  color="primary"
                  disabled={!roomName}
                  onClick={() => joinRoom(roomName, !!isPublic)}
                >
                  Играть
                </Button>
              </Box>
            </Container>
          </Section>
        </Page>
        <Footer />
      </div>
    </>
  );
});
