import { CustomList } from "components/WaitingRoom/Modals/CustomList";
import { RoomSettings } from "backend/src/common/types/roomState";
import { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bulma-components";
import { updateSettings } from "socket/events/updateSettings";
import { store } from "store/store";

const getDefaultSettings = (): RoomSettings => ({
  cardsPerUserAliveAnimal: 1,
  cardsPerUserInitial: 6,
  foodAbundance: {
    from: 3,
    to: 18,
  },
  totalCardLimit: 200,
  disabledCards: {},
});

type ModalProps = {
  active: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CustomModal: React.FC<ModalProps> = ({ active, setActive }) => {
  const settingsInStore = store.settings;
  const [settings, setSettings] = useState<RoomSettings>(() => settingsInStore);
  useEffect(() => {
    setSettings(settingsInStore);
  }, [settingsInStore]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      updateSettings(settings);
    }, 300);
    return () => clearTimeout(timeout);
  }, [settings]);
  const isHost = store.players[0] === store.currentPlayer;
  const [listVisible, setListVisible] = useState(false);
  return (
    <>
      <Modal show={active} onClose={() => setActive(false)}>
        <Modal.Card>
          <Modal.Card.Header>
            <Modal.Card.Title>Настройки</Modal.Card.Title>
          </Modal.Card.Header>
          <Modal.Card.Body>
            <Form.Field>
              <Form.Label>Карт каждому изначально: </Form.Label>
              <Form.Control>
                <input
                  type="range"
                  min="0"
                  max="50"
                  disabled={!isHost}
                  value={settings.cardsPerUserInitial}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      cardsPerUserInitial: parseInt(e.target.value),
                    });
                  }}
                />{" "}
                {settings.cardsPerUserInitial}
              </Form.Control>
              <Form.Label>Карт за выжившее животное: </Form.Label>
              <Form.Control>
                <input
                  type="range"
                  min="1"
                  max="5"
                  disabled={!isHost}
                  value={settings.cardsPerUserAliveAnimal}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      cardsPerUserAliveAnimal: parseInt(e.target.value),
                    });
                  }}
                />{" "}
                {settings.cardsPerUserAliveAnimal}
              </Form.Control>
              <Form.Label>Всего карт в колоде: </Form.Label>
              <Form.Control>
                <input
                  type="range"
                  min="0"
                  max="500"
                  disabled={!isHost}
                  value={settings.totalCardLimit}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      totalCardLimit: parseInt(e.target.value),
                    });
                  }}
                />{" "}
                {settings.totalCardLimit}
              </Form.Control>

              <Form.Label>Еды от </Form.Label>
              <Form.Control>
                <input
                  type="range"
                  min="0"
                  max="50"
                  disabled={!isHost}
                  value={settings.foodAbundance.from}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      foodAbundance: {
                        from: parseInt(e.target.value),
                        to: Math.max(
                          settings.foodAbundance.to,
                          parseInt(e.target.value) + 1,
                        ),
                      },
                    });
                  }}
                />{" "}
                {settings.foodAbundance.from}{" "}
              </Form.Control>
              <Form.Label>до </Form.Label>
              <Form.Control>
                <input
                  type="range"
                  min="1"
                  max="51"
                  disabled={!isHost}
                  value={settings.foodAbundance.to}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      foodAbundance: {
                        from: Math.min(
                          settings.foodAbundance.from,
                          parseInt(e.target.value) - 1,
                        ),
                        to: parseInt(e.target.value),
                      },
                    });
                  }}
                />{" "}
                {settings.foodAbundance.to}
              </Form.Control>
            </Form.Field>
            <Button.Group display="flex" justifyContent="space-between">
              <Button onClick={() => setListVisible(!listVisible)}>
                Настройка колоды
              </Button>
              <Button
                onClick={() => {
                  setSettings(getDefaultSettings());
                  setActive(false);
                }}
              >
                Сбросить настройки
              </Button>
            </Button.Group>
            <CustomList
              visible={listVisible}
              settings={settings}
              setSettings={setSettings}
            />
          </Modal.Card.Body>
        </Modal.Card>
      </Modal>
    </>
  );
};
