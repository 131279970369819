import { Card } from "components/Card/Card";

import {
  AnimalCard as AnimalCardType,
  ExtensionType,
} from "backend/src/common/types/ExtensionCard";
import { AnimalCardModelType } from "store/models/AnimalCard";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { getCardClassName } from "utils/getCardClassName";
import { extensionCards } from "backend/src/common/gameData/extensions";

import styles from "./styles.module.scss";
import { store } from "store/store";
import { GameStage } from "backend/src/common/types/roomState";
import { observer } from "mobx-react-lite";
import Draggable from "gsap/Draggable";
import { eatAnimal } from "socket/events/eatAnimal";
import gsap from "gsap";
import { CardHeaderOnly } from "components/Card/CardHeaderOnly";
import { renameAnimal } from "socket/events/renameAnimal";
import { getAnimalDefaultName } from "utils/getAnimalDefaultName";
import { AnimalModal } from "components/AnimalModal/AnimalModal";

import lizard from "resources/lizardGreen.svg";
import lizardPink from "resources/lizardPink.svg";
import mike from "resources/mike.svg";
import egg from "resources/egg.svg";
import { getAnimalMeta } from "utils/getAnimalMeta";
import { canAttack } from "backend/src/common/utils/canAttack";
import classNames from "classnames";
import { useRefPosition } from "hooks/useRefPosition";
import { useSubscription } from "hooks/useSubscription";
import { Connection, ConnectionProps } from "components/Connection/Connection";
import { useRerenderOnTransition } from "hooks/useRerenderOnTransition";
import { reorderCards } from "socket/events/reorderCards";
import { Arrow } from "components/Arrow/Arrow";
import { getVisibleExtensions } from "backend/src/common/utils/getVisibleExtensions";
import { getAnimalContext } from "utils/context/getAnimalContext";
import { getExtensionAdditionalFood } from "backend/src/common/utils/getExtensionAdditionalFood";
import { getExtensionContext } from "utils/context/getExtensionContext";
import { ExtensionCardModelType } from "store/models/ExtensionCard";
import { ExtensionTag } from "components/ExtensionTag/ExtensionTag";
import { Tag } from "react-bulma-components";
import { ScrollYoyo } from "components/ScrollYoyo/ScrollYoyo";
import { useHover } from "hooks/useHover";
import { isFull } from "backend/src/common/utils/isFull";
import { confirm } from "components/Confirm/Confirm";
import { getPlayerAvatar } from "utils/getPlayerAvatar";

const MIKE_NAME = "Mike Wazowski".toLowerCase();

type AnimalCardProps = {
  card: AnimalCardModelType;
  index: number;
  isZooming: boolean;
  playerIndex: number;
  x?: number;
  hoverable?: boolean;
  other?: boolean;
};

const getElementLeftTop = (element: HTMLElement) => {
  const { left, top } = element.getBoundingClientRect();
  return {
    x: left,
    y: top,
  };
};

const getElementCenter = (element: HTMLElement) => {
  const { left, top, width, height } = element.getBoundingClientRect();
  return {
    x: left + width / 2,
    y: top + height / 2,
  };
};

type Position = { x: number; y: number };
const isInsideElement = (position: Position, element: Element): boolean => {
  const rect = element.getBoundingClientRect();
  return (
    position.x >= rect.left &&
    position.x <= rect.right &&
    position.y >= rect.top &&
    position.y <= rect.bottom
  );
};

export const AnimalCard: React.FC<AnimalCardProps> = observer(
  ({ card, index, hoverable, other, playerIndex, isZooming }) => {
    const cardExtensions = useMemo(
      () =>
        getVisibleExtensions(
          card.extensions as ExtensionCardModelType[],
          !!other,
        ),
      [card.extensions, other],
    );
    useSubscription(store.currentPlayer?.cards.length);
    useSubscription(store.draggingAnimal);
    useSubscription(store.gameState.stage);
    useRerenderOnTransition(isZooming);
    const [draggingFromPoint, setDraggingFromPoint] = useState<null | {
      x: number;
      y: number;
    }>();
    const [draggingToPoint, setDraggingToPoint] = useState<null | {
      x: number;
      y: number;
    }>();
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const canNotEat = store.draggingApple
      ? !other && (isFull(getAnimalContext(card), true) || card.cantEat)
      : false;
    const canNotBeEaten = store.draggingAnimal
      ? !canAttack(
          store.draggingAnimal as AnimalCardType,
          getAnimalContext(card),
        ).canAttack
      : false;
    const firstExtensionRef = useRef<HTMLDivElement | null>(null);
    const cardRef = useRef<HTMLDivElement | null>(null);
    const triggerRef = useRef<HTMLDivElement | null>(null);
    const cardPosition = useRefPosition(firstExtensionRef);
    useLayoutEffect(() => {
      if (cardPosition) {
        card.setPosition(cardPosition);
      }
    }, [card, cardPosition]);

    const iAmActiveUser = store.iAmActiveUser;
    const isPredator = cardExtensions.some(
      (extestion) => extestion.visibleType === ExtensionType.predator,
    );
    const isEatingStage = store.gameState.stage === GameStage.eating;
    const isEditingLayout = store.isEditingLayout;
    useEffect(() => {
      const cardElement = triggerRef.current;
      let tween: null | gsap.core.Tween = null;
      let relativePosition: Position = { x: 0, y: 0 };
      if (
        !isEditingLayout &&
        !other &&
        isPredator &&
        iAmActiveUser &&
        isEatingStage &&
        cardElement &&
        !card.isFullWithFat
      ) {
        const draggable = new Draggable(cardElement, {
          onDragStart() {
            const rect = cardElement.getBoundingClientRect();
            relativePosition = {
              x: this.pointerX - rect.left,
              y: this.pointerY - rect.top,
            };
            store.setDraggingSomething(true);
            store.setDraggingAnimal(card);
            setDraggingFromPoint(getElementCenter(cardElement));
            if (tween) {
              tween.kill();
            }
          },
          onDrag() {
            const leftTop = getElementLeftTop(cardElement);
            setDraggingToPoint({
              x: leftTop.x + relativePosition.x,
              y: leftTop.y + relativePosition.y,
            });
          },
          onDragEnd() {
            const handle = async () => {
              store.setDraggingAnimal(null);
              store.setDraggingSomething(false);
              const leftTop = getElementLeftTop(cardElement);
              const center = {
                x: leftTop.x + relativePosition.x,
                y: leftTop.y + relativePosition.y,
              };
              const someEnemyAnimalHit = Array.from(
                document.querySelectorAll(`[data-type="enemy-animal-card"]`),
              ).find((element) => isInsideElement(center, element));
              const someSelfAnimalHit = Array.from(
                document.querySelectorAll(`[data-type="animal-card"]`),
              ).find(
                (element) =>
                  !cardElement.contains(element) &&
                  isInsideElement(center, element),
              );

              const someAnimalHit = someEnemyAnimalHit || someSelfAnimalHit;

              if (someAnimalHit) {
                const animalIndex = Number(
                  someAnimalHit.getAttribute("data-index"),
                );
                const cardPlayerIndex = Number(
                  someAnimalHit.getAttribute("data-player-index"),
                );
                const confirmation =
                  cardPlayerIndex !== playerIndex ||
                  (await confirm("Это ваше животное. Вы уверены?"));
                if (
                  !isNaN(animalIndex) &&
                  !isNaN(cardPlayerIndex) &&
                  confirmation
                ) {
                  eatAnimal(index, cardPlayerIndex, animalIndex);
                }
              }
              tween = gsap.to(cardElement, {
                x: 0,
                y: 0,
                duration: 0.2,
                onComplete: () => {
                  setDraggingFromPoint(null);
                  setDraggingToPoint(null);
                },
                onUpdate: () => {
                  setDraggingToPoint(getElementCenter(cardElement));
                },
              });
            };
            handle();
          },
        });
        return () => {
          draggable.kill();
        };
      }
    }, [
      iAmActiveUser,
      isPredator,
      isEatingStage,
      other,
      index,
      playerIndex,
      card,
      isEditingLayout,
    ]);
    const foodNeededForAnimal = card.needToEat;
    const fatLimit = card.needToEatWithFat - foodNeededForAnimal;
    const hasFat = card.extensions.reduce((acc, extension) => {
      if (extension.data?.storedFood) {
        return acc + extension.data.storedFood;
      }
      return acc;
    }, 0);
    const meta = getAnimalMeta(card);

    const connections: ConnectionProps[] = useMemo(() => {
      const connectedExtensions = cardExtensions.filter(
        (extension) => extension.pairWithAnimal,
      );
      let currentOuter = 0;
      const connectionsData = connectedExtensions
        .map((extension) => {
          const pairId = extension.pairWithAnimal;
          const player = card.player;
          if (pairId && player) {
            for (let i = index + 1; i < player.cards.length; i++) {
              if (player.cards[i].id === pairId) {
                return {
                  from: card.position,
                  to: player.cards[i].position,
                  label: extensionCards[extension.visibleType].meta.name,
                  outer: currentOuter++,
                };
              }
            }
          }
          return false;
        })
        .filter(Boolean) as ConnectionProps[];
      return connectionsData;
    }, [cardExtensions, card.position, index, card.player]);

    let imageSrc = lizard;
    if (
      cardExtensions.some((extension) =>
        [ExtensionType.poisonous, ExtensionType.pseudoPoisonous].includes(
          extension.visibleType,
        ),
      )
    ) {
      imageSrc = lizardPink;
    }
    if (
      cardExtensions.some(
        (extension) => extension.visibleType === ExtensionType.egg,
      )
    ) {
      imageSrc = egg;
    }
    if (card.meta.name.toLowerCase() === MIKE_NAME) {
      imageSrc = mike;
    }
    const playerWithSameName = store.players.find(
      (player) =>
        player.meta.name.toLowerCase() === card.meta.name.toLowerCase(),
    );
    if (playerWithSameName) {
      imageSrc = getPlayerAvatar(playerWithSameName);
    }

    const [isHovered, onMouseOver, onMouseOut, onClick] = useHover();

    return (
      <div
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        className={classNames(styles.cardContainer, {
          [styles.canNotBeEaten]: canNotBeEaten || canNotEat,
          [styles.hovered]: isHovered,
        })}
        ref={cardRef}
        data-animal-id={card.id}
        onClick={() => {
          onClick();
          setIsOpened(true);
        }}
      >
        {draggingFromPoint && draggingToPoint && (
          <Arrow from={draggingFromPoint} to={draggingToPoint} />
        )}
        {connections.map((connection, connectionIndex) => {
          if (
            !connection.from.x ||
            !connection.from.y ||
            !connection.to.x ||
            !connection.to.y
          ) {
            return null;
          }
          return (
            <Connection
              key={connectionIndex}
              outer={connection.outer}
              label={connection.label}
              from={connection.from}
              to={connection.to}
            />
          );
        })}
        {isOpened && (
          <AnimalModal
            other={!!other}
            onClose={() => setIsOpened(false)}
            card={card}
            index={index}
            playerIndex={playerIndex}
          />
        )}
        <div
          className={classNames(styles.trigger, {
            [styles.inactive]: store.gameState.stage === GameStage.placing,
          })}
          ref={triggerRef}
        ></div>
        <div className={styles.extensions}>
          {cardExtensions.map((extension, extIndex) => {
            const extensionData = extensionCards[extension.visibleType];

            return (
              <CardHeaderOnly
                key={extIndex}
                yellow={extension.visibleType === ExtensionType.fat}
                red={extension.visibleType === ExtensionType.predator}
                pink={extensionData.canBeAddedToEnemyAnimal}
                used={extension.wasUsedInCurrentStage}
                disabled={extension.isDisabledByTumor}
              />
            );
          })}
        </div>
        <div className={styles.card} ref={firstExtensionRef}>
          <Card
            onTitleChange={
              !other ? (value) => renameAnimal(index, value) : undefined
            }
            title={
              card.meta.name || getAnimalDefaultName(card as AnimalCardType)
            }
            cardClassName={getCardClassName(card)}
            data-type={other ? "enemy-animal-card" : "animal-card"}
            data-index={index}
            data-player-index={playerIndex}
            hoverable={hoverable}
            red={cardExtensions.some(
              (extension) => extension.visibleType === ExtensionType.predator,
            )}
            blue={cardExtensions.some(
              (extension) => extension.visibleType === ExtensionType.swimming,
            )}
            afterTitle={
              store.gameState.stage === GameStage.eating
                ? `${Math.min(
                    card.satiety,
                    foodNeededForAnimal,
                  )} / ${foodNeededForAnimal}`
                : foodNeededForAnimal
            }
          >
            <ScrollYoyo className={styles.extensionsView} active={isHovered}>
              <Tag.Group>
                {cardExtensions.map((extension, extIndex) => {
                  const extensionData = extensionCards[extension.visibleType];

                  let afterTitle = "";
                  const extensionFood = getExtensionAdditionalFood(
                    getExtensionContext(extension),
                  );
                  if (extensionFood) {
                    afterTitle = `+${extensionFood}`;
                  }
                  if (extension.data?.storedFood) {
                    afterTitle = `${extension.data.storedFood}`;
                  }

                  return (
                    <ExtensionTag
                      title={
                        extensionData.meta.shortName || extensionData.meta.name
                      }
                      key={extIndex}
                      afterTitle={afterTitle}
                      afterTitleColor={
                        extension.data?.storedFood ? "#8d7a00" : "#6b0101"
                      }
                      yellow={extension.visibleType === ExtensionType.fat}
                      red={extension.visibleType === ExtensionType.predator}
                      pink={extensionData.canBeAddedToEnemyAnimal}
                      used={extension.wasUsedInCurrentStage}
                      disabled={extension.isDisabledByTumor}
                    />
                  );
                })}
              </Tag.Group>
            </ScrollYoyo>
            <div className={styles.cardContent}>
              <div className={styles.meta}>{meta}</div>
              <div className={styles.image}>
                <img src={imageSrc} />
              </div>
              <div className={styles.panel}>
                {fatLimit !== 0 && hasFat !== 0 && (
                  <div
                    className={styles.fatProgress}
                    style={{
                      width: `${Math.min(100, (100 * hasFat) / fatLimit)}%`,
                    }}
                  />
                )}
                {store.gameState.stage === GameStage.eating &&
                  card.satiety !== 0 && (
                    <div
                      className={styles.progress}
                      style={{
                        width: `${Math.min(
                          100,
                          (100 * card.satiety) / foodNeededForAnimal,
                        )}%`,
                      }}
                    />
                  )}
              </div>
            </div>
            {store.isPlacing && !other && (
              <div className={styles.controls}>
                <div
                  className={styles.left}
                  onClick={(e) => {
                    if (index !== 0) {
                      e.stopPropagation();
                      reorderCards(index, index - 1);
                    }
                  }}
                >
                  &larr;
                </div>
                <div
                  className={styles.right}
                  onClick={(e) => {
                    if (index !== store.players[playerIndex].cards.length - 1) {
                      e.stopPropagation();
                      reorderCards(index, index + 1);
                    }
                  }}
                >
                  &rarr;
                </div>
              </div>
            )}
          </Card>
        </div>
      </div>
    );
  },
);
