import { GameStage, RoomState } from "backend/src/common/types/roomState";
import { getOrCreateSocket } from "socket/socket";
import { store } from "store/store";

export const eatAnimal = (
  animalCardIndex: number,
  preyPlayerIndex: number,
  preyAnimalIndex: number,
) => {
  const socket = getOrCreateSocket();
  if (
    store.currentRoom &&
    store.roomState === RoomState.playing &&
    store.iAmActiveUser &&
    store.gameState.stage === GameStage.eating &&
    store.currentPlayer?.cards[animalCardIndex] &&
    store.players[preyPlayerIndex]?.cards[preyAnimalIndex]
  ) {
    socket.emit(
      "eatAnimal",
      store.currentRoom,
      animalCardIndex,
      preyPlayerIndex,
      preyAnimalIndex,
    );
  }
};
