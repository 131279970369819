import { useState, useEffect } from "react";

export const useRerenderOnTransition = (
  forceTransition: boolean = false,
): number => {
  const [transitionsActive, setTransitionsActive] = useState<number>(0);
  const [rerenderKey, setRerenderKey] = useState<number>(0);

  useEffect(() => {
    if (transitionsActive || forceTransition) {
      window.requestAnimationFrame(() => setRerenderKey(rerenderKey + 1));
    } else {
      setRerenderKey(0);
    }
  }, [transitionsActive, rerenderKey, forceTransition]);

  useEffect(() => {
    const onTransitionStart = (): void => {
      setTransitionsActive((c) => c + 1);
    };
    const onTransitionEnd = (): void => {
      setTransitionsActive((c) => c - 1);
    };
    const body = document.body;
    if (body) {
      body.addEventListener("transitionstart", onTransitionStart);
      body.addEventListener("transitionend", onTransitionEnd);
      return () => {
        body.removeEventListener("transitionstart", onTransitionStart);
        body.removeEventListener("transitionend", onTransitionEnd);
      };
    }
  }, []);

  return rerenderKey;
};
