import { RoomState } from "backend/src/common/types/roomState";
import { getOrCreateSocket } from "socket/socket";
import { store } from "store/store";

export const placeExtensionOnAnimal = (
  deckCardIndex: number,
  animalCardIndex: number | [number, number],
  enemyUserIndex?: number,
) => {
  const socket = getOrCreateSocket();
  if (
    store.currentRoom &&
    store.roomState === RoomState.playing &&
    store.iAmActiveUser
  ) {
    socket.emit(
      "placeExtensionOnAnimal",
      store.currentRoom,
      deckCardIndex,
      animalCardIndex,
      enemyUserIndex,
    );
  }
};
