import { extensionCards } from '../gameData/extensions';
import { AnimalContext } from '../types/ExtensionCard';
import { getExtensionAdditionalFood } from './getExtensionAdditionalFood';

const BASE_FOOD_NEED = 1;

export const foodNeeded = (
  context: AnimalContext,
  withAdditionalFood?: boolean,
): number => {
  const needToEat =
    BASE_FOOD_NEED +
    context.animal.extensions.reduce((sum, extension) => {
      const extensionData = extensionCards[extension.type];
      const additionalFood = getExtensionAdditionalFood({
        ...context,
        extension,
      });
      sum += additionalFood || 0;
      if (withAdditionalFood) {
        sum += extensionData.optionalAdditionalFood || 0;
      }
      return sum;
    }, 0);
  return needToEat;
};
