import { extensionCards } from "backend/src/common/gameData/extensions";
import { ExtensionType } from "backend/src/common/types/ExtensionCard";
import { AnimalCardModelType } from "store/models/AnimalCard";
import { getAnimalContext } from "./context/getAnimalContext";
import { pluralize } from "./pluralize";

export const getAnimalMeta = (card: AnimalCardModelType): string => {
  const meta = [
    pluralize("age", card.age),
    card.isBlocked &&
      card.extensions.some(
        (extension) => extension.type === ExtensionType.predator,
      ) &&
      `не может атаковать`,
    card.isPoisoned && `отравлено`,
    card.isHidden && `спряталось`,
    card.cantEat && `не может есть`,
    card.patronsCount > 0 && `под защитой`,
    card.isFull && `сытое`,
    ...card.extensions
      .map((extension) =>
        extensionCards[extension.type].getAdditionalMeta?.(
          getAnimalContext(card),
        ),
      )
      .filter(Boolean),
  ]
    .filter(Boolean)
    .join(", ");
  return meta;
};
