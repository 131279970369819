import { extensionCards } from '../gameData/extensions';
import { AnimalCard, SimplifiedExtensionCard } from '../types/ExtensionCard';

export const getWorkingExtensions = (
  animal: AnimalCard,
): SimplifiedExtensionCard[] => {
  const isEggLike = animal.extensions.some(
    (extension) => extensionCards[extension.type].isBlockingEverything,
  );
  if (isEggLike) {
    return [];
  }
  return animal.extensions.filter((extension) => !extension.isDisabledByTumor);
};
