import gsap from "gsap";
import Draggable from "gsap/Draggable";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import { eatPlant } from "socket/events/eatPlant";
import { store } from "store/store";

import styles from "./styles.module.scss";

type FoodItemProps = {
  active: boolean;
};

export const FoodItem: React.FC<FoodItemProps> = observer(({ active }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const alive = useRef<boolean>(true);
  useEffect(() => {
    alive.current = true;
    return () => {
      alive.current = false;
    };
  }, []);

  const isEditingLayout = store.isEditingLayout;
  useEffect(() => {
    const target = ref.current;
    if (target && active && !isEditingLayout) {
      const draggable = new Draggable(target, {
        onDragStart() {
          store.setDraggingApple(true);
        },
        onDragEnd() {
          store.setDraggingApple(false);
          if (store.iAmActiveUser) {
            const someAnimalHit = Array.from(
              document.querySelectorAll(`[data-type="animal-card"]`),
            ).find((element) => this.hitTest(element, "50%"));

            if (someAnimalHit) {
              const animalIndex = Number(
                someAnimalHit.getAttribute("data-index"),
              );
              if (!isNaN(animalIndex)) {
                eatPlant(animalIndex);
                gsap.to(target, {
                  opacity: 0.2,
                  onComplete: () => {
                    if (alive.current) {
                      gsap.to(target, {
                        x: this.startX,
                        y: this.startY,
                        opacity: 1,
                      });
                    }
                  },
                });
                return;
              }
            }
          }

          gsap.to(target, { x: this.startX, y: this.startY });
        },
      });
      return () => {
        draggable.kill();
      };
    }
  }, [active, isEditingLayout]);

  return (
    <div ref={ref} className={styles.foodItem}>
      🍎
    </div>
  );
});
