import React from "react";

import styles from "./styles.module.scss";

type PageProps = {};

export const Page: React.FC<React.PropsWithChildren<PageProps>> = ({
  children,
}) => {
  return <div className={styles.page}>{children}</div>;
};
