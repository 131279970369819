import { extensionCards } from '../gameData/extensions';
import { ExtensionType, SimplifiedExtensionCard } from '../types/ExtensionCard';
import { GameState } from '../types/roomState';

export const canExtensionBeUsed = (
  gameState: GameState,
  extension: SimplifiedExtensionCard,
) => {
  const extensionData = extensionCards[extension.type];
  if (extensionData.canNotBeUsedInLastStep && gameState.cardsLeft <= 0) {
    return false;
  }
  if (
    extensionData.canBeUsedOnceInStage &&
    (extension.wasUsedInPreviousStage || extension.wasUsedInCurrentStage)
  ) {
    return false;
  }
  if (!extensionData.canBeUsedOnStages?.includes(gameState.stage)) {
    return false;
  }
  if (extension.isDisabledByTumor) {
    return false;
  }
  if (
    extension.type === ExtensionType.fat &&
    (!extension?.data?.storedFood || extension?.data?.storedFood <= 0)
  ) {
    return false;
  }
  return true;
};
