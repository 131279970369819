import { extensionCards } from "backend/src/common/gameData/extensions";
import { ExtensionType } from "backend/src/common/types/ExtensionCard";
import { RoomSettings } from "backend/src/common/types/roomState";

import { Table, Form } from "react-bulma-components";
import { store } from "store/store";

type ListProps = {
  visible: boolean;
  settings: RoomSettings;
  setSettings: React.Dispatch<React.SetStateAction<RoomSettings>>;
};
export const CustomList: React.FC<ListProps> = ({
  visible,
  settings,
  setSettings,
}) => {
  const isHost = store.players[0] === store.currentPlayer;
  if (!visible) return null;
  const isSelectedAll = Object.values(settings.disabledCards).every(
    (checked) => !checked,
  );
  return (
    <>
      <Table.Container>
        <Table size="fullwidth" bordered={true}>
          <thead>
            <tr>
              <th>
                <Form.Checkbox
                  checked={isSelectedAll}
                  type="checkbox"
                  onChange={() => {
                    setSettings({
                      ...settings,
                      disabledCards: Object.values(ExtensionType).reduce(
                        (acc, type) => {
                          acc[type] = isSelectedAll;
                          return acc;
                        },
                        {} as Record<ExtensionType, boolean>,
                      ),
                    });
                  }}
                />
                Выбрать всё
              </th>
              <th>Карта</th>
            </tr>
          </thead>
          <tbody>
            {Object.values(ExtensionType).map((type) => {
              return (
                <tr key={type}>
                  <td>
                    <label>
                      <input
                        type="checkbox"
                        disabled={!isHost}
                        onChange={() => {
                          setSettings({
                            ...settings,
                            disabledCards: {
                              ...settings.disabledCards,
                              [type]: !settings.disabledCards[type],
                            },
                          });
                        }}
                        checked={!settings.disabledCards[type]}
                      />{" "}
                    </label>
                  </td>
                  <td>{extensionCards[type].meta.name}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Table.Container>
    </>
  );
};
