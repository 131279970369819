import { io, Socket } from "socket.io-client";
import { getOrCreateMyId } from "utils/getOrCreateMyId";

import {
  ServerToClientEvents,
  ClientToServerEvents,
} from "backend/src/common/types/socket";

let savedSocket: null | Socket<ServerToClientEvents, ClientToServerEvents> =
  null;

export const getOrCreateSocket = (): Socket<
  ServerToClientEvents,
  ClientToServerEvents
> => {
  if (!savedSocket) {
    savedSocket = io(`${process.env.REACT_APP_PROXY_HOST || "/"}`, {
      reconnectionDelayMax: 10000,
      query: {
        id: getOrCreateMyId(),
      },
      rejectUnauthorized: false,
    });
  }
  return savedSocket;
};
