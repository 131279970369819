import {
  AnimalCard,
  AnimalContext,
  ExtensionType,
} from '../types/ExtensionCard';
import { extensionCards } from '../gameData/extensions';
import { getWorkingExtensions } from './getWorkingExtensions';

type CanAttackResultPositive = {
  intellectUsed?: number;
  canAttack: true;
};

type CanAttackResultNegative = {
  canAttack: false;
  reasons: string[];
};

type CanAttackResult = CanAttackResultPositive | CanAttackResultNegative;

export const canAttack = (
  predator: AnimalCard,
  preyContext: AnimalContext,
): CanAttackResult => {
  if (
    !getWorkingExtensions(predator).some(
      (extension) => extension.type === ExtensionType.predator,
    )
  ) {
    return {
      canAttack: false,
      reasons: ['Атакующий должен быть хищником'],
    };
  }

  if (predator === preyContext.animal) {
    return {
      canAttack: false,
      reasons: ['Нельзя атаковать себя'],
    };
  }

  if (predator.cantEat) {
    return {
      canAttack: false,
      reasons: ['Хищник не может есть'],
    };
  }

  if (predator.isBlocked) {
    return {
      canAttack: false,
      reasons: ['Хищник не может атаковать до конца хода'],
    };
  }

  if (
    predator.extensions.some(
      (extension) => extensionCards[extension.type].isBlockingEverything,
    )
  ) {
    return {
      canAttack: false,
      reasons: ['Хищник яйцо'],
    };
  }

  if (
    preyContext.animal.extensions.some(
      (extension) => extensionCards[extension.type].isBlockingEverything,
    )
  ) {
    return {
      canAttack: true,
    };
  }

  let intellectUsed = 0;
  let intellectCount = getWorkingExtensions(predator).filter(
    (extension) =>
      extension.type === ExtensionType.intellect &&
      !extension.wasUsedInCurrentStage,
  ).length;

  const ignoreWithIntellect = () => {
    intellectCount--;
    intellectUsed++;
    if (intellectCount >= 0) {
      return true;
    }
    return false;
  };

  if (preyContext.animal.isHidden && !ignoreWithIntellect()) {
    return {
      canAttack: false,
      reasons: ['Животное не видно'],
    };
  }

  if (preyContext.animal.patronsCount > 0 && !ignoreWithIntellect()) {
    return {
      canAttack: false,
      reasons: ['Животное под защитой'],
    };
  }

  const canAttackResults = getWorkingExtensions(predator).map(
    (extension): CanAttackResult => {
      const extensionData = extensionCards[extension.type];
      if (!extensionData.canAttack) {
        return {
          canAttack: true,
        };
      }
      const localCanAttack = extensionData.canAttack(
        predator,
        preyContext.animal,
      );
      return {
        canAttack: localCanAttack,
        reasons: [extensionData.meta.description],
      };
    },
  );

  const defenceExtensionsResults = getWorkingExtensions(preyContext.animal).map(
    (extension): CanAttackResult => {
      const extensionData = extensionCards[extension.type];
      if (!extensionData.canBeAttacked) {
        return {
          canAttack: true,
        };
      }
      const localCanAttack = extensionData.canBeAttacked(preyContext, predator);
      return {
        canAttack: localCanAttack,
        reasons: [extensionData.meta.description],
      };
    },
  );

  const defenceResultsBlockedAttack = [
    ...canAttackResults,
    ...defenceExtensionsResults,
  ].filter(
    (result) => !result.canAttack && !ignoreWithIntellect(),
  ) as CanAttackResultNegative[];

  if (defenceResultsBlockedAttack.length === 0) {
    return {
      intellectUsed,
      canAttack: true,
    };
  }

  return {
    canAttack: false,
    reasons: defenceResultsBlockedAttack
      .flatMap((result) => result.reasons)
      .filter(Boolean),
  };
};
