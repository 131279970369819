import React, { useEffect, useRef } from "react";

type ScrollYoyoProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  active: boolean;
};

const ANIMATION_DURATION = 6000;

export const ScrollYoyo: React.FC<React.PropsWithChildren<ScrollYoyoProps>> = ({
  active,
  children,
  ...containerProps
}) => {
  const elementRef = useRef<null | HTMLDivElement>(null);
  const startTime = useRef<number>(0);
  useEffect(() => {
    const element = elementRef.current;
    if (active && element && element.scrollHeight !== element.offsetHeight) {
      startTime.current = new Date().getTime();
      const interval = setInterval(() => {
        const now = new Date().getTime();
        const currentTime = (now - startTime.current) % ANIMATION_DURATION;
        const progress = currentTime / ANIMATION_DURATION;
        const scrollPercent =
          1 - (Math.cos(progress * 360 * (Math.PI / 180)) + 1) / 2;
        const scrollTo =
          (element.scrollHeight - element.offsetHeight) * scrollPercent;
        element.scrollTo(0, scrollTo);
      }, 10);
      return () => {
        element.scrollTo(0, 0);
        clearInterval(interval);
      };
    }
  }, [active]);
  return (
    <div {...containerProps} ref={elementRef}>
      {children}
    </div>
  );
};
