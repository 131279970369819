import { extensionCards } from '../gameData/extensions';
import { SimplifiedExtensionCard } from '../types/ExtensionCard';

export const getVisibleExtensions = <T extends SimplifiedExtensionCard>(
  extensions: T[],
  applyFilter: boolean = true,
): T[] => {
  if (!applyFilter) {
    return extensions;
  }
  return extensions.filter((extension) => {
    return !extensionCards[extension.type].isHidden || extension.wasDisclosed;
  });
};
