import { GameStage, GameState, Player, Room } from './roomState';

export enum ExtensionType {
  predator = 'predator',
  camouflage = 'camouflage',
  sharpVision = 'sharpVision',
  swimming = 'swimming',
  running = 'running',
  big = 'big',
  crazing = 'crazing',
  poisonous = 'poisonous',
  tailLoss = 'tailLoss',
  hibernation = 'hibernation',
  scavenger = 'scavenger',
  burrowing = 'burrowing',
  piracy = 'piracy',
  parasite = 'parasite',
  parasitePair = 'parasitePair',
  cooperation = 'cooperation',
  communication = 'communication',
  fat = 'fat',
  fly = 'fly',
  angler = 'angler',
  pseudoPoisonous = 'pseudoPoisonous',
  viviparous = 'viviparous',
  layingEggs = 'layingEggs',
  egg = 'egg',
  ink = 'ink',
  spikes = 'spikes',
  scream = 'scream',
  patron = 'patron',
  transparent = 'transparent',
  horns = 'horns',
  aedificator = 'aedificator',
  immunity = 'immunity',
  intellect = 'intellect',
  tumor = 'tumor',
  shell = 'shell',
}

type FullContext = {
  room: Room;
  player: Player;
  animal: AnimalCard;
  extension: SimplifiedExtensionCard;
};

export type RoomContext = Pick<FullContext, 'room'>;
export type PlayerContext = Pick<FullContext, 'room' | 'player'>;
export type AnimalContext = Pick<FullContext, 'room' | 'player' | 'animal'>;
export type ExtensionContext = Pick<
  FullContext,
  'room' | 'player' | 'animal' | 'extension'
>;

export type ExtensionCallback<
  Context extends RoomContext,
  ReturnType extends unknown,
  Attributes extends unknown[] = [],
> = (context: Context, ...attrs: Attributes) => ReturnType;

type ExtensionCallbacks = Partial<{
  /** Extension level callbacks */
  onSelfDeath: ExtensionCallback<ExtensionContext, void>;
  defenceChance: ExtensionCallback<
    ExtensionContext,
    boolean,
    [predator: AnimalCard]
  >;

  /** Animal level callbacks */
  onExtensionPlaced: ExtensionCallback<AnimalContext, void>;
  canBeAttacked: ExtensionCallback<
    AnimalContext,
    boolean,
    [predator: AnimalCard]
  >;
  getAdditionalMeta: ExtensionCallback<AnimalContext, string | null>;

  canAttack: (animal: AnimalCard, self: AnimalCard) => boolean;
  afterDefence: (
    room: Room,
    animal: AnimalCard,
    self: AnimalCard,
    extension: SimplifiedExtensionCard,
  ) => void;
  afterBeingKilled: (
    animal: AnimalCard,
    self: AnimalCard,
    owner: Player,
  ) => void;
  onSomeoneDeath: (self: AnimalCard, room: Room) => void;
  onBeingFeed: (
    room: Room,
    self: SimplifiedExtensionCard,
    animal: AnimalCard,
    fromPlant: boolean,
  ) => void;
  onUse: (data: {
    room: Room;
    game: GameState;
    self: AnimalCard;
    extension: SimplifiedExtensionCard;
  }) => void;
  canBeUsed: (data: {
    room: Room;
    self: AnimalCard;
    extension: SimplifiedExtensionCard;
  }) => boolean;
  onAdditionalFood: (self: SimplifiedExtensionCard) => boolean;
  onStageEnd: (animal: AnimalCard, self: SimplifiedExtensionCard) => void;
  onStageStart: (animal: AnimalCard, player: Player) => void;
}>;

export type ExtensionCard = ExtensionCallbacks & {
  type: ExtensionType;
  additionalFood: number;
  additionalFoodLabel?: string;
  customAdditionalFood?: (context: ExtensionContext) => number;
  optionalAdditionalFood?: number;

  chanceModifier: number;

  canBeUsedOnStages?: GameStage[];
  canBeUsedOnceInStage?: true;
  canNotBeUsedInLastStep?: true;
  canBeAddedToEnemyAnimal?: true;
  canBeAddeddMultipleTimes?: true;
  canBeInherited?: true;
  isBlockingEverything?: true;
  isHidden?: true;
  shouldBePaired?: true;
  inheritChance?: number;
  mimics?: ExtensionType;
  conflictsWith?: ExtensionType[];

  meta: {
    name: string;
    shortName?: string;
    description: string;
  };
};

export type SimplifiedExtensionCard = Pick<ExtensionCard, 'type'> & {
  id: string;

  wasUsedInCurrentStage?: boolean;
  wasUsedInPreviousStage?: boolean;
  wasUsedInCurrentTick?: boolean;
  wasDisclosed?: boolean;
  isDisabledByTumor?: boolean;

  pairWithAnimal?: string;
  wasPlacedByUser?: string;

  data?: {
    storedFood?: number;
    stepsToBeBorn?: number;
  };
};

export type AnimalCard = {
  fromId: string;
  id: string;
  extensions: SimplifiedExtensionCard[];
  satiety: number;
  isKilled: boolean;
  isBlocked: boolean;
  isPoisoned: boolean;
  isHidden: boolean;
  cantEat: boolean;
  patronsCount: number;
  meta: {
    name?: string;
  };
  age: number;
};
