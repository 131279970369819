import { HandType } from "backend/src/common/types/roomState";
import { Instance, types } from "mobx-state-tree";
import { AnimalCardModel } from "./AnimalCard";
import { ExtensionCardModel } from "./ExtensionCard";

export const PlayerModel = types.model({
  id: types.string,
  cards: types.optional(types.array(AnimalCardModel), []),
  deck: types.optional(types.array(ExtensionCardModel), []),
  meta: types.model({
    name: types.optional(types.string, ""),
  }),
  handType: types.optional(
    types.enumeration<HandType>(Object.values(HandType)),
    HandType.human,
  ),
  avatarHash: types.optional(types.string, ""),
  isBot: types.optional(types.boolean, false),
});

export type PlayerModelType = Instance<typeof PlayerModel>;
