/* eslint-disable @typescript-eslint/no-use-before-define */
import { deletePairedExtension } from '../../game/processAnimalDeath';
import getContext from '../../utils/context/getContext';
import { getRandomId } from '../utils/getRandomId';
import { getWorkingExtensions } from '../utils/getWorkingExtensions';
import { logToPlayer, logToRoom } from '../../utils/logToPlayer';
import {
  AnimalCard,
  AnimalContext,
  ExtensionCard,
  ExtensionType,
  SimplifiedExtensionCard,
} from '../types/ExtensionCard';
import { GameStage, Player } from '../types/roomState';
import { declOfNum } from '../utils/declOfNum';
import { foodNeeded } from '../utils/foodNeeded';
import { isFull } from '../utils/isFull';
import { random } from '../utils/random';

type PartialExtensionCard = Omit<ExtensionCard, 'cardType' | 'type'>;

const getInheritedExtensions = (
  context: AnimalContext,
): SimplifiedExtensionCard[] => {
  const inheritedExtensions: SimplifiedExtensionCard[] = [];
  context.animal.extensions.forEach((extension) => {
    if (extensionCards[extension.type].canBeInherited) {
      const chance = extensionCards[extension.type].inheritChance || 1;
      if (Math.random() < 0.05 * chance) {
        inheritedExtensions.push({
          ...extension,
          id: getRandomId(),
          data: {},
        });
      }
    }
  });
  return inheritedExtensions;
};

const extensions: Record<ExtensionType, PartialExtensionCard> = {
  [ExtensionType.predator]: {
    additionalFood: 1,
    chanceModifier: 1,
    canBeInherited: true,
    meta: {
      name: 'Хищник',
      description: 'Может нападать на других животных',
    },
  },
  [ExtensionType.aedificator]: {
    additionalFood: 0,
    chanceModifier: 0.5,
    canBeInherited: true,
    meta: {
      name: 'Эдификатор',
      shortName: 'Эдиф.',
      description: 'Добавляет две единицы еды в кормовую базу в начале хода',
    },
  },
  [ExtensionType.horns]: {
    additionalFood: 0,
    chanceModifier: 0.5,
    canBeInherited: true,
    defenceChance: () => Math.random() <= 0.4,
    afterDefence: (_room, animal) => {
      animal.isKilled = true;
    },
    meta: {
      name: 'Рога',
      description: 'После атаки имеет шанс 60% убить хищника',
    },
  },
  [ExtensionType.transparent]: {
    additionalFood: 0,
    chanceModifier: 0.5,
    conflictsWith: [ExtensionType.burrowing],
    canBeInherited: true,
    canBeAttacked: ({ animal }) => animal.satiety !== 0,
    meta: {
      name: 'Прозрачность',
      shortName: 'Прозр.',
      description: 'Пока не получало еду, не может быть атаковано',
    },
  },
  [ExtensionType.camouflage]: {
    additionalFood: 0,
    chanceModifier: 0.5,
    canBeInherited: true,
    canBeAttacked: (_context, predator) =>
      getWorkingExtensions(predator).some(
        (extension) => `${extension.type}` === `${ExtensionType.sharpVision}`,
      ),
    meta: {
      name: 'Камуфляж',
      description: 'Может быть атаковано только хищником с острым зрением',
    },
  },
  [ExtensionType.sharpVision]: {
    additionalFood: 0,
    chanceModifier: 0.5,
    canBeInherited: true,
    meta: {
      name: 'Острое зрение',
      shortName: 'Остр. зр.',
      description: 'Может атаковать животных со свойством камуфляж',
    },
  },
  [ExtensionType.big]: {
    additionalFood: 1,
    chanceModifier: 0.5,
    canBeInherited: true,
    canBeAttacked: (_context, predator) =>
      getWorkingExtensions(predator).some(
        (extension) => extension.type === ExtensionType.big,
      ),
    meta: {
      name: 'Большой',
      description: 'Может быть атаковано только большим хищником',
    },
  },
  [ExtensionType.burrowing]: {
    additionalFood: 0,
    chanceModifier: 0.5,
    canBeInherited: true,
    conflictsWith: [ExtensionType.transparent],
    canBeAttacked: (context) => !isFull(context),
    getAdditionalMeta: (context) => (isFull(context) ? 'в норе' : null),
    meta: {
      name: 'Норное',
      description: 'Когда накормлено, не может быть атаковано хищником',
    },
  },
  [ExtensionType.crazing]: {
    additionalFood: 0,
    chanceModifier: 0.5,
    canBeUsedOnStages: [GameStage.eating],
    canBeUsed: ({ room }) => !!room.gameState.foodOnTheTable,
    onUse: ({ room, game }) => {
      game.foodOnTheTable = Math.max(0, game.foodOnTheTable - 1);
      logToRoom(room, 'Топотун уничтожает единицу еды', 'warning');
    },
    meta: {
      name: 'Топотун',
      description: 'При использовании уничтожает единицу еды из кормовой базы',
    },
  },
  [ExtensionType.hibernation]: {
    additionalFood: 0,
    chanceModifier: 0.5,
    canBeInherited: true,
    canBeUsedOnStages: [GameStage.eating],
    canBeUsedOnceInStage: true,
    canNotBeUsedInLastStep: true,
    onUse: ({ room, self }) => {
      const owner = room.players.find((player) =>
        player.cards.some((card) => card.id === self.id),
      );
      if (owner) {
        self.satiety = foodNeeded(getContext(room, { animal: self }));
      }
    },
    meta: {
      name: 'Спячка',
      description:
        'После использования считается накормленным. Нельзя использовать два раза подряд и в последний ход игры',
    },
  },
  [ExtensionType.poisonous]: {
    additionalFood: 0,
    canBeInherited: true,
    chanceModifier: 0.2,
    afterBeingKilled: (predator) => (predator.isPoisoned = true),
    meta: {
      name: 'Ядовитое',
      shortName: 'Яд.',
      description: 'Хищник, съевший это животное, умрет в фазу вымирания',
    },
  },
  [ExtensionType.running]: {
    additionalFood: 0,
    chanceModifier: 0.5,
    canBeInherited: true,
    defenceChance: (_context, predator) =>
      Math.random() >=
      (getWorkingExtensions(predator).some(
        (ext) => ext.type === ExtensionType.predator,
      )
        ? 0.9
        : 0.5),
    afterDefence: (room, predator, self) => {
      const owner = room.players.find((player) =>
        player.cards.some((card) => card.id === self.id),
      );
      if (owner) {
        logToPlayer(owner, 'Ваше животное убежало от хищника', 'info');
      }
      predator.isBlocked = true;
    },
    meta: {
      name: 'Быстрое',
      description:
        'Когда атаковано, есть шанс не быть съеденным (50% для обычного хищника, 10% для быстрого)',
    },
  },
  [ExtensionType.scavenger]: {
    additionalFood: 0,
    chanceModifier: 0.5,
    canBeInherited: true,
    onSomeoneDeath: (self, room) => {
      if (self.cantEat) {
        return;
      }
      const owner = room.players.find((player) =>
        player.cards.some((card) => card.id === self.id),
      );
      if (owner) {
        logToPlayer(owner, 'Ваше животное доело за хищником', 'info');
      }
      self.satiety++;
      getWorkingExtensions(self).forEach((extension) => {
        const extensionData = extensionCards[extension.type];
        if (extensionData.onBeingFeed) {
          extensionData.onBeingFeed(room, extension, self, false);
        }
      });
    },
    meta: {
      name: 'Падальщик',
      description: 'Получает единицу еды, когда съедено другое животное',
    },
  },
  [ExtensionType.swimming]: {
    additionalFood: 0,
    chanceModifier: 0.5,
    canBeInherited: true,
    canBeAttacked: (_context, predator) =>
      getWorkingExtensions(predator).some(
        (extension) => extension.type === ExtensionType.swimming,
      ),
    canAttack: (_predator, prey) =>
      getWorkingExtensions(prey).some(
        (extension) => extension.type === ExtensionType.swimming,
      ),
    meta: {
      name: 'Водоплавающее',
      shortName: 'Вод.',
      description:
        'Может быть атаковано только водоплавающим хищником. Может атаковать только водоплавающих животных',
    },
  },
  [ExtensionType.tailLoss]: {
    additionalFood: 0,
    chanceModifier: 0.5,
    canBeInherited: true,
    defenceChance: () => true,
    afterDefence: (room, animal, self) => {
      const parasiteIndex = self.extensions.findIndex(
        (extension) => extension.type === ExtensionType.parasite,
      );
      const haveParasite = parasiteIndex !== -1;
      const shouldSpliceParasite = haveParasite && Math.random() < 0.5;
      let extensionIndex = shouldSpliceParasite
        ? parasiteIndex
        : random(0, self.extensions.length - 1);
      deletePairedExtension(
        getContext(room, { extension: self.extensions[extensionIndex] }),
      );

      const owner = room.players.find((player) =>
        player.cards.some((card) => card.id === self.id),
      );
      if (owner) {
        logToPlayer(
          owner,
          `Ваше животное отбросило карту "${
            extensionCards[self.extensions[extensionIndex]?.type]?.meta.name
          }"`,
          'info',
        );
      }

      self.extensions.splice(extensionIndex, 1);

      const animalContext = getContext(room, { animal });
      const basicFull = isFull(animalContext);
      const fatFull = isFull(animalContext, true);
      if (!basicFull) {
        animal.satiety++;
      }
      if (basicFull && !fatFull) {
        let additionalFoodApplied = false;
        getWorkingExtensions(animal).forEach((extension) => {
          const extensionData = extensionCards[extension.type];
          if (extensionData.onAdditionalFood && !additionalFoodApplied) {
            additionalFoodApplied = extensionData.onAdditionalFood(extension);
          }
        });
      }
    },
    meta: {
      name: 'Отбрасывание хвоста',
      shortName: 'Отбр. хв.',
      description:
        'Когда атаковано, сбрасывает это или другое случайное свойство животного, чтобы выжить',
    },
  },
  [ExtensionType.piracy]: {
    additionalFood: 0,
    chanceModifier: 0.5,
    canBeUsed: ({ room, self }) => {
      const fatFull = isFull(getContext(room, { animal: self }), true);
      if (fatFull) {
        return false;
      }
      if (self.cantEat) {
        return false;
      }
      const avaiableAnimals: {
        owner: Player;
        animal: AnimalCard;
      }[] = room.players
        .filter((player) => player.id !== room.gameState.activeUser)
        .flatMap((player) =>
          player.cards
            .filter(
              (card) =>
                !isFull(getContext(room, { animal: card })) && card.satiety > 0,
            )
            .map((animal) => ({
              owner: player,
              animal,
            })),
        );

      return avaiableAnimals.length > 0;
    },
    onUse: ({ room, game, self }) => {
      const selfContext = getContext(room, { animal: self });
      const basicFull = isFull(selfContext);
      const fatFull = isFull(selfContext, true);
      if (fatFull) {
        return;
      }
      const avaiableAnimals: {
        owner: Player;
        animal: AnimalCard;
      }[] = room.players
        .filter((player) => player.id !== game.activeUser)
        .flatMap((player) =>
          player.cards
            .filter(
              (card) =>
                !isFull({
                  room,
                  player,
                  animal: card,
                }) && card.satiety > 0,
            )
            .map((animal) => ({
              owner: player,
              animal,
            })),
        );
      if (avaiableAnimals.length !== 0) {
        const usedAnimal =
          avaiableAnimals[random(0, avaiableAnimals.length - 1)];
        usedAnimal.animal.satiety--;
        logToPlayer(
          usedAnimal.owner,
          `У вашего животного украл еду ${
            room.players.find((player) => player.id === game.activeUser)?.meta
              .name ?? 'другой игрок'
          }`,
          'warning',
        );
        if (!basicFull) {
          self.satiety++;
        }
        if (basicFull && !fatFull) {
          let additionalFoodApplied = false;
          getWorkingExtensions(self).forEach((extension) => {
            const extensionData = extensionCards[extension.type];
            if (extensionData.onAdditionalFood && !additionalFoodApplied) {
              additionalFoodApplied = extensionData.onAdditionalFood(extension);
            }
          });
        }
        getWorkingExtensions(self).forEach((extension) => {
          const extensionData = extensionCards[extension.type];
          if (extensionData.onBeingFeed) {
            extensionData.onBeingFeed(room, extension, self, false);
          }
        });
      }
    },
    canBeUsedOnStages: [GameStage.eating],
    meta: {
      name: 'Пиратство',
      description: 'Заберите еду у другого ненакормленного животного',
    },
  },
  [ExtensionType.parasite]: {
    additionalFood: 2,
    chanceModifier: 0.3,
    canBeAddedToEnemyAnimal: true,
    conflictsWith: [ExtensionType.immunity],
    meta: {
      name: 'Паразит',
      description: 'Можно добавить чужому животному',
    },
  },
  [ExtensionType.scream]: {
    additionalFood: 0,
    chanceModifier: 0.5,
    afterBeingKilled: (_predator, animal, owner) => {
      const cardIndex = owner.cards.indexOf(animal);
      if (cardIndex !== -1) {
        const prevCard = owner.cards[cardIndex - 1];
        const postCard = owner.cards[cardIndex + 1];
        if (prevCard) {
          prevCard.isHidden = true;
        }
        if (postCard) {
          postCard.isHidden = true;
        }
      }
    },
    meta: {
      name: 'Крик',
      description: 'При нападении предупреждает соседних животных',
    },
  },
  [ExtensionType.spikes]: {
    additionalFood: 0,
    chanceModifier: 0.5,
    canBeInherited: true,
    afterBeingKilled: (animal) => {
      animal.isBlocked = true;
    },
    meta: {
      name: 'Шипы',
      description:
        'После атаки на животное хищник не может атаковать до конца хода',
    },
  },
  [ExtensionType.parasitePair]: {
    additionalFood: 1,
    chanceModifier: 0.3,
    canBeAddedToEnemyAnimal: true,
    shouldBePaired: true,
    conflictsWith: [ExtensionType.immunity],
    meta: {
      name: 'Трематода',
      description: 'Можно добавить паре чужих животных',
    },
  },
  [ExtensionType.fat]: {
    additionalFood: 0,
    chanceModifier: 0.5,
    canBeInherited: true,
    inheritChance: 0.5,
    optionalAdditionalFood: 1,
    canBeAddeddMultipleTimes: true,
    canBeUsedOnceInStage: true,
    canBeUsedOnStages: [GameStage.eating],
    onAdditionalFood: (self) => {
      if (self.data?.storedFood) {
        return false;
      }
      if (!self.data) {
        self.data = {};
      }
      self.data.storedFood = 1;
      return true;
    },
    canBeUsed: ({ room, self, extension }) =>
      !!extension.data?.storedFood ||
      isFull(getContext(room, { animal: self })),
    onUse: ({ self, extension }) => {
      if (extension.data?.storedFood) {
        self.satiety++;
        extension.data.storedFood--;
      }
    },
    meta: {
      name: 'Жировой запас',
      shortName: 'Жир. зап.',
      description: 'При использовании тратит накопленную энергию для питания',
    },
  },
  [ExtensionType.cooperation]: {
    additionalFood: 0,
    shouldBePaired: true,
    chanceModifier: 0.3,
    onBeingFeed: (room, self, animal) => {
      if (self.wasUsedInCurrentTick) {
        return;
      }
      if (
        animal.extensions.some(
          (extension) => extensionCards[extension.type].isBlockingEverything,
        )
      ) {
        return;
      }
      if (self.pairWithAnimal) {
        room.players.some((player) =>
          player.cards.some((card) => {
            if (card.id === self.pairWithAnimal) {
              if (
                !isFull({
                  room,
                  player,
                  animal: card,
                }) &&
                !card.cantEat
              ) {
                card.satiety++;
                self.wasUsedInCurrentTick = true;

                const selfClone = card.extensions.find(
                  (extension) => extension.id === self.id,
                );
                if (selfClone) {
                  selfClone.wasUsedInCurrentTick = true;
                }

                getWorkingExtensions(card).forEach((extension) => {
                  if (!extension.wasUsedInCurrentTick) {
                    extensionCards[extension.type].onBeingFeed?.(
                      room,
                      extension,
                      card,
                      false,
                    );
                  }
                });
              }
            }
          }),
        );
      }
    },
    meta: {
      name: 'Сотрудничество',
      shortName: 'Сотрудн.',
      description:
        'Когда одно животное из пары получает еду, второе получает тоже',
    },
  },
  [ExtensionType.communication]: {
    additionalFood: 0,
    chanceModifier: 0.5,
    shouldBePaired: true,
    onBeingFeed: (room, self, animal, fromPlant) => {
      if (!fromPlant) {
        return;
      }
      if (self.wasUsedInCurrentTick) {
        return;
      }
      if (
        animal.extensions.some(
          (extension) => extensionCards[extension.type].isBlockingEverything,
        )
      ) {
        return;
      }
      if (self.pairWithAnimal) {
        room.players.some((player) =>
          player.cards.some((card) => {
            if (card.id === self.pairWithAnimal) {
              if (
                !isFull({
                  room,
                  player,
                  animal: card,
                }) &&
                room.gameState.foodOnTheTable > 0 &&
                !card.cantEat
              ) {
                room.gameState.foodOnTheTable--;
                card.satiety++;
                self.wasUsedInCurrentTick = true;

                const selfClone = card.extensions.find(
                  (extension) => extension.id === self.id,
                );
                if (selfClone) {
                  selfClone.wasUsedInCurrentTick = true;
                }

                getWorkingExtensions(card).forEach((extension) => {
                  if (!extension.wasUsedInCurrentTick) {
                    extensionCards[extension.type].onBeingFeed?.(
                      room,
                      extension,
                      card,
                      true,
                    );
                  }
                });
              }
            }
          }),
        );
      }
    },
    meta: {
      name: 'Взаимодействие',
      shortName: 'Взаим.',
      description:
        'Когда одно животное из пары получает еду из кормовой базы, второе получает еду из кормовой базы вне очереди',
    },
  },
  [ExtensionType.fly]: {
    additionalFood: 0,
    chanceModifier: 0.5,
    canBeInherited: true,
    getAdditionalMeta: ({ animal }) => `${animal.extensions.length}кг`,
    canBeAttacked: ({ animal }, predator) => {
      return animal.extensions.length > predator.extensions.length;
    },
    meta: {
      name: 'Полет',
      description: 'Не может быть атаковано более "тяжелым" хищником',
    },
  },
  [ExtensionType.angler]: {
    additionalFood: 0,
    chanceModifier: 0.2,
    isHidden: true,
    defenceChance: ({ animal }) =>
      animal.extensions.length === 1 &&
      animal.extensions[0].type === ExtensionType.angler &&
      !animal.extensions[0].wasDisclosed,
    afterDefence: (_room, animal, self) => {
      self.satiety += 2;
      const extension = self.extensions.find(
        (extension) => extension.type === ExtensionType.angler,
      );
      if (extension) {
        extension.wasDisclosed = true;
      }
      animal.isKilled = true;
    },
    meta: {
      name: 'Удильщик',
      description:
        'Пока не раскрыт и является единственным расширением, атакует хищника в ответ. Не показывается соперникам',
    },
  },
  [ExtensionType.pseudoPoisonous]: {
    additionalFood: 0,
    chanceModifier: 0.5,
    canBeInherited: true,
    conflictsWith: [ExtensionType.poisonous],
    mimics: ExtensionType.poisonous,
    meta: {
      name: 'Яркая раскраска',
      shortName: 'Ярк. рас.',
      description: 'Выглядит, как ядовитое. Карта тоже.',
    },
  },
  [ExtensionType.egg]: {
    additionalFood: 0,
    chanceModifier: 0,
    isBlockingEverything: true,
    onStageEnd: (animal, self) => {
      if (self.data?.stepsToBeBorn) {
        self.data.stepsToBeBorn--;
        if (self.data.stepsToBeBorn === 0) {
          animal.extensions = animal.extensions.filter(
            (extension) => extension.id !== self.id,
          );
          if (animal.meta.name === 'Яйцо') {
            animal.meta.name = 'Детеныш';
          }
        }
      }
    },
    getAdditionalMeta: ({ animal }) => {
      const eggExtension = animal.extensions.find(
        (extension) => extension.type === ExtensionType.egg,
      );
      if (eggExtension?.data?.stepsToBeBorn) {
        return `вылупится через ${eggExtension.data.stepsToBeBorn} ${declOfNum(
          eggExtension.data.stepsToBeBorn,
          ['год', 'года', 'лет'],
        )}`;
      }
      return '';
    },
    meta: {
      name: 'Яйцо',
      description:
        'Это животное еще не вылупилось. Оно ничего не может делать.',
    },
  },
  [ExtensionType.layingEggs]: {
    additionalFood: 0,
    chanceModifier: 0.12,
    canBeInherited: true,
    inheritChance: 0.1,
    conflictsWith: [ExtensionType.viviparous],
    onBeingFeed: (room, self, animal) => {
      if (self.wasUsedInCurrentStage) {
        return;
      }
      const parent = room.players.find((player) =>
        player.cards.some((card) => card.id === animal.id),
      );
      if (
        parent &&
        isFull({
          room,
          player: parent,
          animal,
        })
      ) {
        self.wasUsedInCurrentStage = true;
        const eggsCount = Math.floor(1 + Math.random() * 3);
        if (parent) {
          for (let i = 0; i < eggsCount; i++) {
            const inheritedExtensions = getInheritedExtensions({
              room,
              player: parent,
              animal,
            });
            const egg = {
              id: getRandomId(),
              extensions: [
                ...inheritedExtensions,
                {
                  type: ExtensionType.egg,
                  id: getRandomId(),
                  data: {
                    stepsToBeBorn: Math.floor(2 + Math.random() * 3),
                  },
                },
              ],
              satiety: 2,
              isBlocked: true,
              isPoisoned: false,
              isKilled: false,
              isHidden: false,
              cantEat: false,
              patronsCount: 0,
              fromId: '',
              age: 0,
              meta: {
                name: 'Яйцо',
              },
            };
            egg.satiety = foodNeeded({
              room,
              player: parent,
              animal: egg,
            });
            parent.cards.push(egg);
          }
        }
      }
    },
    meta: {
      name: 'Откладывание яиц',
      shortName: 'Откл. яиц',
      description:
        'Когда накормлено, откладывает несколько яиц. Они вылупятся через несколько ходов',
    },
  },
  [ExtensionType.viviparous]: {
    additionalFood: 0,
    chanceModifier: 0.3,
    canBeInherited: true,
    inheritChance: 0.1,
    conflictsWith: [ExtensionType.layingEggs],
    onBeingFeed: (room, self, animal) => {
      if (self.wasUsedInCurrentStage) {
        return;
      }
      const parent = room.players.find((player) =>
        player.cards.some((card) => card.id === animal.id),
      );
      if (
        parent &&
        isFull({
          room,
          player: parent,
          animal,
        })
      ) {
        self.wasUsedInCurrentStage = true;
        if (parent) {
          const inheritedExtensions = getInheritedExtensions({
            room,
            player: parent,
            animal,
          });
          const child = {
            id: getRandomId(),
            extensions: inheritedExtensions,
            satiety: 1,
            isBlocked: true,
            isPoisoned: false,
            isKilled: false,
            isHidden: false,
            cantEat: false,
            patronsCount: 0,
            fromId: '',
            age: 0,
            meta: {
              name: 'Детеныш',
            },
          };
          child.satiety = foodNeeded({
            room,
            player: parent,
            animal: child,
          });
          parent.cards.push(child);
        }
      }
    },
    meta: {
      name: 'Живорождение',
      shortName: 'Живор.',
      description:
        'Когда накормлено, рожает ребенка. Ребенок может унаследовать свойства родителя',
    },
  },
  [ExtensionType.ink]: {
    additionalFood: 0,
    chanceModifier: 0.5,
    canBeInherited: true,
    defenceChance: ({ extension }) => !extension.wasUsedInCurrentStage,
    afterDefence: (room, _predator, animal, extension) => {
      extension.wasUsedInCurrentStage = true;
      const owner = room.players.find((player) =>
        player.cards.some((card) => card.id === animal.id),
      );
      if (owner) {
        logToPlayer(owner, `Ваше животное использовало чернильное облако`);
      }
    },
    meta: {
      name: 'Чернильное облако',
      shortName: 'Черн. обл.',
      description: 'Один раз за ход прерывает атаку на себя',
    },
  },
  [ExtensionType.patron]: {
    additionalFood: 0,
    chanceModifier: 0.5,
    onStageStart: (self, player) => {
      const selfIndex = player.cards.findIndex((card) => card.id === self.id);
      if (selfIndex !== -1) {
        const animal = player.cards[selfIndex];
        const patronExtension = animal.extensions.find(
          (extension) => extension.type === ExtensionType.patron,
        );
        if (patronExtension?.isDisabledByTumor) {
          return;
        }
        const cardOnTheLeft = player.cards[selfIndex - 1];
        const cardOnTheRight = player.cards[selfIndex + 1];
        if (cardOnTheLeft && cardOnTheLeft.extensions.length === 0) {
          cardOnTheLeft.patronsCount++;
        }
        if (cardOnTheRight && cardOnTheRight.extensions.length === 0) {
          cardOnTheRight.patronsCount++;
        }
      }
    },
    onSelfDeath: ({ animal, player }) => {
      const selfIndex = player.cards.findIndex((card) => card.id === animal.id);
      if (selfIndex !== -1) {
        const animal = player.cards[selfIndex];
        const patronExtension = animal.extensions.find(
          (extension) => extension.type === ExtensionType.patron,
        );
        if (patronExtension?.isDisabledByTumor) {
          return;
        }
        const cardOnTheLeft = player.cards[selfIndex - 1];
        const cardOnTheRight = player.cards[selfIndex + 1];
        if (cardOnTheLeft && cardOnTheLeft.extensions.length === 0) {
          cardOnTheLeft.patronsCount--;
        }
        if (cardOnTheRight && cardOnTheRight.extensions.length === 0) {
          cardOnTheRight.patronsCount--;
        }
      }
    },
    meta: {
      name: 'Покровитель',
      description: 'Защищает соседних животных без свойств',
    },
  },
  [ExtensionType.immunity]: {
    additionalFood: 1,
    chanceModifier: 0.5,
    canBeInherited: true,
    onExtensionPlaced: (context) => {
      const removedExtensions: SimplifiedExtensionCard[] = [];
      context.animal.extensions = context.animal.extensions.filter(
        (extension) => {
          const conflictsWith = extensionCards[extension.type].conflictsWith;
          if (conflictsWith) {
            const hasConflict = conflictsWith.includes(ExtensionType.immunity);
            if (hasConflict) {
              removedExtensions.push(extension);
            }
            return !hasConflict;
          }
          return true;
        },
      );
      context.animal.extensions.forEach(
        (extension) => (extension.isDisabledByTumor = false),
      );
      removedExtensions.forEach((extension) =>
        deletePairedExtension({ ...context, extension }),
      );
    },
    meta: {
      name: 'Иммунитет',
      description:
        'На это животное нельзя положить паразитов. Существующие паразиты пропадают',
    },
  },
  [ExtensionType.intellect]: {
    additionalFood: 1,
    additionalFoodLabel: '+1/3/5...',
    customAdditionalFood: (context) => {
      const intellectExtensions = context.animal.extensions.filter(
        (extension) => extension.type === ExtensionType.intellect,
      );
      const selfIndex = intellectExtensions.indexOf(context.extension);
      return 1 + selfIndex * 2;
    },
    chanceModifier: 0.5,
    canBeInherited: true,
    inheritChance: 0.5,
    canBeAddeddMultipleTimes: true,
    meta: {
      name: 'Интеллект',
      shortName: '🧠',
      description:
        'Один раз за ход игнорирует одну карту защиты другого животного',
    },
  },
  [ExtensionType.tumor]: {
    conflictsWith: [ExtensionType.immunity],
    additionalFood: 0,
    chanceModifier: 0.2,
    canBeAddedToEnemyAnimal: true,
    onExtensionPlaced: ({ animal }) => {
      const tumorIndex = animal.extensions.findIndex(
        (extension) => extension.type === ExtensionType.tumor,
      );
      if (tumorIndex !== -1) {
        // place tumor on first place
        const [tumor] = animal.extensions.splice(tumorIndex, 1);
        animal.extensions.unshift(tumor);
      }
    },
    onStageEnd: (animal, extension) => {
      const tumorIndex = animal.extensions.indexOf(extension);
      if (tumorIndex !== -1) {
        // swap extension with next one
        const nextTumorIndex = tumorIndex + 1;
        if (nextTumorIndex < animal.extensions.length) {
          const nextTumor = animal.extensions[nextTumorIndex];
          animal.extensions[tumorIndex] = nextTumor;
          animal.extensions[nextTumorIndex] = extension;
          for (let i = 0; i < nextTumorIndex; i++) {
            const prevExtension = animal.extensions[i];
            prevExtension.isDisabledByTumor = true;
          }
        } else {
          animal.isKilled = true;
        }
      }
    },
    meta: {
      name: 'Неоплазия',
      description: 'Постепенно убивает животное',
    },
  },
  [ExtensionType.shell]: {
    additionalFood: 0,
    chanceModifier: 0.1,
    defenceChance: () => true,
    afterDefence: (_room, animal, self) => {
      self.isHidden = true;
      self.cantEat = true;
      animal.isBlocked = true;
    },
    onSelfDeath: (context) => {
      context.room.commonExtensions.push(context.extension);
    },
    meta: {
      name: 'Раковина',
      description:
        'Во время атаки прячется в раковину, и не может есть до конца хода. Если умирает, раковина попадает в пищевую базу',
    },
  },
};

export const extensionCards = Object.fromEntries(
  Object.entries(extensions).map(([key, value]) => [
    key,
    { ...value, type: key },
  ]),
) as unknown as Record<ExtensionType, ExtensionCard>;
