import { extensionCards } from "backend/src/common/gameData/extensions";
import { ExtensionCardModelType } from "store/models/ExtensionCard";

import styles from "./styles.module.scss";
import { useEffect, useRef, useState } from "react";
import { Draggable } from "gsap/Draggable";
import gsap from "gsap";
import { store } from "store/store";
import { placeExtensionOnAnimal } from "socket/events/placeExtensionOnAnimal";

type ExtensionIconProps = {
  card: ExtensionCardModelType;
  canBeAddedToAnimals?: boolean;
  index: number;
  icon: React.ReactNode;
  onAdd?: () => void;
};

export const ExtensionIcon: React.FC<ExtensionIconProps> = ({
  card,
  canBeAddedToAnimals,
  index,
  icon,
  onAdd,
}) => {
  const extensionData = extensionCards[card.type];
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const cardRef = useRef<HTMLDivElement | null>(null);

  const alive = useRef<boolean>(true);
  useEffect(() => {
    alive.current = true;
    return () => {
      alive.current = false;
    };
  }, []);

  const isEditingLayout = store.isEditingLayout;
  useEffect(() => {
    if (canBeAddedToAnimals) {
      const target = cardRef.current;
      if (target && !isEditingLayout) {
        const draggable = new Draggable(target, {
          onDragStart() {
            setIsDragging(true);
            store.setDraggingSomething(true);
          },
          onDrag() {
            const opacity = gsap.getProperty(target, "opacity");
            if (![0, 1].includes(Number(opacity))) {
              return;
            }
            Array.from(
              document.querySelectorAll(
                `[data-type="${
                  extensionData.canBeAddedToEnemyAnimal
                    ? "enemy-animal-card"
                    : "animal-card"
                }"]`,
              ),
            ).forEach((element) => {
              const hit = this.hitTest(element, "30%");
              if (hit) {
                element.classList.add("hovered");
              } else {
                element.classList.remove("hovered");
              }
              return hit;
            });
          },
          onDragEnd() {
            store.setDraggingSomething(false);
            document
              .querySelectorAll(
                `[data-type="${
                  extensionData.canBeAddedToEnemyAnimal
                    ? "enemy-animal-card"
                    : "animal-card"
                }"]`,
              )
              .forEach((element) => element.classList.remove("hovered"));

            setIsDragging(false);
            const someAnimalHit = Array.from(
              document.querySelectorAll(
                `[data-type="${
                  extensionData.canBeAddedToEnemyAnimal
                    ? "enemy-animal-card"
                    : "animal-card"
                }"]`,
              ),
            ).filter((element) => this.hitTest(element, "30%"));

            gsap.to(target, {
              opacity: 0.2,
              onComplete: () => {
                if (alive.current) {
                  gsap.to(target, {
                    x: this.startX,
                    y: this.startY,
                    opacity: 1,
                  });
                }
              },
            });
            if (someAnimalHit.length > 0) {
              const animal = someAnimalHit[0];
              if (extensionData.shouldBePaired) {
                if (someAnimalHit.length >= 2) {
                  const indexes = someAnimalHit.map((element) => {
                    return Number(element.getAttribute("data-index"));
                  });
                  const userIndex = Number(
                    animal.getAttribute("data-player-index"),
                  );
                  if (!isNaN(indexes[0]) && !isNaN(indexes[1])) {
                    onAdd?.();
                    placeExtensionOnAnimal(
                      index,
                      [indexes[0], indexes[1]],
                      extensionData.canBeAddedToEnemyAnimal
                        ? userIndex
                        : undefined,
                    );
                  }
                }
                return;
              }
              const animalIndex = Number(animal.getAttribute("data-index"));
              const userIndex = Number(
                animal.getAttribute("data-player-index"),
              );
              if (!isNaN(animalIndex)) {
                onAdd?.();
                placeExtensionOnAnimal(
                  index,
                  Number(animalIndex),
                  extensionData.canBeAddedToEnemyAnimal ? userIndex : undefined,
                );
                return;
              }
              gsap.to(target, { x: this.startX, y: this.startY });
              return;
            }
            gsap.to(target, { x: this.startX, y: this.startY });
          },
        });
        return () => {
          draggable.kill();
        };
      }
    }
  }, [
    canBeAddedToAnimals,
    card,
    index,
    extensionData.canBeAddedToEnemyAnimal,
    extensionData.shouldBePaired,
    onAdd,
    isEditingLayout,
  ]);

  useEffect(() => {
    if (isDragging) {
      document.body.classList.add("dragging-extension");
      return () => {
        document.body.classList.remove("dragging-extension");
      };
    }
  }, [isDragging]);

  return (
    <div className={styles.icon} ref={cardRef}>
      {icon}
    </div>
  );
};
