import { useCallback, useState } from "react";

export const useHover = (): [
  isHovered: boolean,
  onMouseOver: () => void,
  onMouseOut: () => void,
  onClick: () => void,
] => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const onMouseOver = useCallback(() => setIsHovered(true), []);
  const onMouseOut = useCallback(() => setIsHovered(false), []);
  const onClick = useCallback(() => setIsHovered(false), []);
  return [isHovered, onMouseOver, onMouseOut, onClick];
};
