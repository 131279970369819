import { RoomState } from "backend/src/common/types/roomState";
import { getOrCreateSocket } from "socket/socket";
import { store } from "store/store";

export const useExtension = (
  animalCardIndex: number,
  extensionIndex: number,
) => {
  const socket = getOrCreateSocket();
  if (
    store.currentRoom &&
    store.roomState === RoomState.playing &&
    store.iAmActiveUser &&
    store.currentPlayer?.cards[animalCardIndex]?.extensions[extensionIndex]
  ) {
    socket.emit(
      "useExtension",
      store.currentRoom,
      animalCardIndex,
      extensionIndex,
    );
  }
};
