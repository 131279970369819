import { applySnapshot, types } from "mobx-state-tree";
import { RoomSettings } from "backend/src/common/types/roomState";
import { ExtensionType } from "backend/src/common/types/ExtensionCard";

export const SettingsModel = types
  .model({
    cardsPerUserInitial: types.optional(types.number, 6),
    cardsPerUserAliveAnimal: types.optional(types.number, 1),
    foodAbundance: types.model({
      from: types.optional(types.number, 3),
      to: types.optional(types.number, 18),
    }),
    totalCardLimit: types.optional(types.number, 200),
    disabledCards: types.model({
      [ExtensionType.predator]: types.optional(types.boolean, false),
      [ExtensionType.camouflage]: types.optional(types.boolean, false),
      [ExtensionType.sharpVision]: types.optional(types.boolean, false),
      [ExtensionType.swimming]: types.optional(types.boolean, false),
      [ExtensionType.running]: types.optional(types.boolean, false),
      [ExtensionType.big]: types.optional(types.boolean, false),
      [ExtensionType.crazing]: types.optional(types.boolean, false),
      [ExtensionType.poisonous]: types.optional(types.boolean, false),
      [ExtensionType.tailLoss]: types.optional(types.boolean, false),
      [ExtensionType.hibernation]: types.optional(types.boolean, false),
      [ExtensionType.scavenger]: types.optional(types.boolean, false),
      [ExtensionType.burrowing]: types.optional(types.boolean, false),
      [ExtensionType.piracy]: types.optional(types.boolean, false),
      [ExtensionType.parasite]: types.optional(types.boolean, false),
      [ExtensionType.parasitePair]: types.optional(types.boolean, false),
      [ExtensionType.cooperation]: types.optional(types.boolean, false),
      [ExtensionType.communication]: types.optional(types.boolean, false),
      [ExtensionType.fat]: types.optional(types.boolean, false),
      [ExtensionType.fly]: types.optional(types.boolean, false),
      [ExtensionType.angler]: types.optional(types.boolean, false),
      [ExtensionType.pseudoPoisonous]: types.optional(types.boolean, false),
      [ExtensionType.viviparous]: types.optional(types.boolean, false),
      [ExtensionType.layingEggs]: types.optional(types.boolean, false),
      [ExtensionType.egg]: types.optional(types.boolean, false),
      [ExtensionType.ink]: types.optional(types.boolean, false),
      [ExtensionType.spikes]: types.optional(types.boolean, false),
      [ExtensionType.scream]: types.optional(types.boolean, false),
      [ExtensionType.patron]: types.optional(types.boolean, false),
      [ExtensionType.transparent]: types.optional(types.boolean, false),
      [ExtensionType.horns]: types.optional(types.boolean, false),
      [ExtensionType.aedificator]: types.optional(types.boolean, false),
      [ExtensionType.immunity]: types.optional(types.boolean, false),
      [ExtensionType.intellect]: types.optional(types.boolean, false),
      [ExtensionType.tumor]: types.optional(types.boolean, false),
      [ExtensionType.shell]: types.optional(types.boolean, false),
    }),
  })
  .actions((self) => ({
    update(newSettings: RoomSettings) {
      applySnapshot(self, newSettings);
    },
  }));
