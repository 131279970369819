import { RoomState } from "backend/src/common/types/roomState";
import { getOrCreateSocket } from "socket/socket";
import { store } from "store/store";

export const addBot = () => {
  const socket = getOrCreateSocket();
  if (store.currentRoom && store.roomState === RoomState.pending) {
    socket.emit("addBot", store.currentRoom);
  }
};
