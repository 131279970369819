import { Card } from "components/Card/Card";

import {
  AnimalCard as AnimalCardType,
  ExtensionType,
} from "backend/src/common/types/ExtensionCard";
import { AnimalCardModelType } from "store/models/AnimalCard";

import styles from "./styles.module.scss";
import { store } from "store/store";
import { GameStage } from "backend/src/common/types/roomState";
import { observer } from "mobx-react-lite";
import { getAnimalDefaultName } from "utils/getAnimalDefaultName";

import lizard from "resources/lizardGreen.svg";
import lizardPink from "resources/lizardPink.svg";
import egg from "resources/egg.svg";
import mike from "resources/mike.svg";
import { getAnimalMeta } from "utils/getAnimalMeta";
import { renameAnimal } from "socket/events/renameAnimal";
import { getPlayerAvatar } from "utils/getPlayerAvatar";

const MIKE_NAME = "Mike Wazowski".toLowerCase();

type AnimalCardSimpleProps = {
  card: AnimalCardModelType;
  index: number;
  other?: boolean;
};
export const AnimalCardSimple: React.FC<AnimalCardSimpleProps> = observer(
  ({ card, other, index }) => {
    const foodNeededForAnimal = card.needToEat;

    const meta = getAnimalMeta(card);

    let imageSrc = lizard;
    if (
      card.extensions.some((extension) =>
        [ExtensionType.poisonous, ExtensionType.pseudoPoisonous].includes(
          extension.visibleType,
        ),
      )
    ) {
      imageSrc = lizardPink;
    }
    if (
      card.extensions.some(
        (extension) => extension.visibleType === ExtensionType.egg,
      )
    ) {
      imageSrc = egg;
    }
    if (card.meta.name.toLowerCase() === MIKE_NAME) {
      imageSrc = mike;
    }
    const playerWithSameName = store.players.find(
      (player) =>
        player.meta.name.toLowerCase() === card.meta.name.toLowerCase(),
    );
    if (playerWithSameName) {
      imageSrc = getPlayerAvatar(playerWithSameName);
    }

    return (
      <div className={styles.cardContainer}>
        <div className={styles.card}>
          <Card
            onTitleChange={
              !other ? (value) => renameAnimal(index, value) : undefined
            }
            title={
              card.meta.name || getAnimalDefaultName(card as AnimalCardType)
            }
            red={card.extensions.some(
              (extension) => extension.visibleType === ExtensionType.predator,
            )}
            blue={card.extensions.some(
              (extension) => extension.visibleType === ExtensionType.swimming,
            )}
            afterTitle={
              store.gameState.stage === GameStage.eating
                ? `${Math.min(
                    card.satiety,
                    foodNeededForAnimal,
                  )} / ${foodNeededForAnimal}`
                : foodNeededForAnimal
            }
          >
            <div className={styles.cardContent}>
              <div className={styles.meta}>{meta}</div>
              <div className={styles.image}>
                <img src={imageSrc} />
              </div>
              <div className={styles.panel}>
                {store.gameState.stage === GameStage.eating && (
                  <div
                    className={styles.progress}
                    style={{
                      width: `${Math.min(
                        100,
                        (100 * card.satiety) / foodNeededForAnimal,
                      )}%`,
                    }}
                  />
                )}
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  },
);
