import { RoomSettings, RoomState } from "backend/src/common/types/roomState";
import { getOrCreateSocket } from "socket/socket";
import { store } from "store/store";

export const updateSettings = (settings: RoomSettings) => {
  const socket = getOrCreateSocket();
  if (store.currentRoom && store.roomState === RoomState.pending) {
    store.settings.update(settings);
    socket.emit("updateSettings", store.currentRoom, settings);
  }
};
