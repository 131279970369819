import React from "react";
import ReactDOM from "react-dom";
import styles from "./styles.module.scss";

type ArrowProps = {
  from: { x: number; y: number };
  to: { x: number; y: number };
};

export const Arrow: React.FC<ArrowProps> = ({ from, to }) => {
  const angle = (Math.atan2(to.y - from.y, to.x - from.x) * 180) / Math.PI;
  const length = Math.sqrt(
    Math.pow(to.y - from.y, 2) + Math.pow(to.x - from.x, 2),
  );
  const transform = `rotate(${angle}deg)`;
  return ReactDOM.createPortal(
    <div
      className={styles.arrow}
      style={{ transform, top: from.y, left: from.x, width: length }}
    >
      <div className={styles.body} />
      <div className={styles.head} />
    </div>,
    document.body,
  );
};
