import { Card } from "components/Card/Card";

import lizard from "resources/lizardGreen.svg";

import styles from "./styles.module.scss";

type AnimalCardPlaceholderProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  hoverable?: boolean;
};

export const AnimalCardPlaceholder: React.FC<AnimalCardPlaceholderProps> = ({
  hoverable,
  className,
  ...props
}) => {
  return (
    <Card
      title={`Животное`}
      {...props}
      hoverable={hoverable}
      cardClassName={className}
    >
      <div className={styles.cardContent}>
        <div className={styles.image}>
          <img src={lizard} />
        </div>
      </div>
    </Card>
  );
};
