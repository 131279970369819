import { applySnapshot } from "mobx-state-tree";
import { store } from "store/store";
import { toast } from "react-toastify";
import { Player, Room, SimpleRoom } from "backend/src/common/types/roomState";
import { autorun } from "mobx";
import { getOrCreateMyId } from "utils/getOrCreateMyId";
import { getOrCreateSocket } from "./socket";

let updatesPaused: boolean = false;
let delayedUpdate: Room<Player> | null = null;

const applyUpdate = (room: Room<Player>) => {
  store.setRoomState(room.state);
  applySnapshot(store.players, room.players);
  applySnapshot(store.commonExtensions, room.commonExtensions);
  applySnapshot(store.settings, room.settings);
  store.setActions(room.actions);
  if (room.gameState) {
    applySnapshot(store.gameState, room.gameState);
  }
};

autorun(() => {
  if (delayedUpdate && !store.draggingSomething) {
    applyUpdate(delayedUpdate);
    delayedUpdate = null;
  }
});

export const applySocketSubscrptions = () => {
  const socket = getOrCreateSocket();
  socket.on("roomState", async (room) => {
    const currentPlayer = room.players.find(
      (player) => player.id === getOrCreateMyId(),
    );
    if (currentPlayer) {
      const cardsAreDead = store.currentPlayer?.cards.filter(
        (card) =>
          !currentPlayer.cards.some(
            (backendCard) => backendCard.id === card.id,
          ),
      );
      if (cardsAreDead && cardsAreDead.length > 0) {
        delayedUpdate = room;
        updatesPaused = true;
        cardsAreDead.forEach((card) => {
          const element = document.querySelector(
            `[data-animal-id="${card.id}"]`,
          );
          if (element) {
            element.classList.add(
              "animate__animated",
              "animate__fadeOutUp",
              "animate__fast",
            );
          }
        });
        await new Promise((resolve) => setTimeout(resolve, 800));
        updatesPaused = false;
        applyUpdate(delayedUpdate);
        return;
      }
    }
    if (updatesPaused || store.draggingSomething) {
      delayedUpdate = room;
      return;
    }
    applyUpdate(room);
  });

  socket.on("log", (text, type) => {
    toast(text, {
      position: "top-right",
      autoClose: type === "default" ? 5000 : 40000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      type,
    });
  });

  socket.on("openRooms", (rooms: SimpleRoom[]) => {
    store.setOpenRooms(rooms);
  });
};
