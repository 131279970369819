import classNames from "classnames";
import styles from "./styles.module.scss";

type CardProps = {
  red?: boolean;
  blue?: boolean;
  pink?: boolean;
  yellow?: boolean;
  used?: boolean;
  disabled?: boolean;
};

export const CardHeaderOnly: React.FC<React.PropsWithChildren<CardProps>> = ({
  red,
  blue,
  pink,
  used,
  disabled,
  yellow,
}) => {
  return (
    <div
      className={classNames(styles.card, styles.onlyHeader, {
        [styles.red]: red,
        [styles.blue]: blue,
        [styles.pink]: pink,
        [styles.yellow]: yellow,
        [styles.used]: used,
        [styles.disabled]: disabled,
      })}
    >
      <div className={styles.titleContainer} />
    </div>
  );
};
