import { getParent, Instance, types } from "mobx-state-tree";
import { ExtensionType } from "backend/src/common/types/ExtensionCard";
import { ExtensionCardModel } from "./ExtensionCard";
import { isFull } from "backend/src/common/utils/isFull";
import { Point } from "hooks/useRefPosition";
import { PlayerModelType } from "./Player";
import { foodNeeded } from "backend/src/common/utils/foodNeeded";
import { getRoomContext } from "utils/context/getRoomContext";

export const AnimalCardModel = types
  .model({
    fromId: types.optional(types.string, ""),
    id: types.optional(types.string, ""),
    extensions: types.array(ExtensionCardModel),
    satiety: types.optional(types.number, 0),
    meta: types.model({
      name: types.optional(types.string, ""),
    }),
    isKilled: types.optional(types.boolean, false),
    isBlocked: types.optional(types.boolean, false),
    isPoisoned: types.optional(types.boolean, false),
    isHidden: types.optional(types.boolean, false),
    cantEat: types.optional(types.boolean, false),
    patronsCount: types.optional(types.number, 0),
    age: types.optional(types.number, 0),
    position: types.optional(
      types.model({
        x: types.optional(types.number, 0),
        y: types.optional(types.number, 0),
      }),
      {},
    ),
  })
  .actions((self) => ({
    addExtension: (type: ExtensionType) => {
      self.extensions.push({ type });
    },
    setPosition: (point: Point) => {
      self.position = point;
    },
  }))
  .views((self) => ({
    get owner(): PlayerModelType {
      return getParent(self, 2) as PlayerModelType;
    },
    get player(): PlayerModelType {
      return getParent(self, 2) as PlayerModelType;
    },
    get isFull(): boolean {
      return isFull({
        ...getRoomContext(),
        player: this.owner,
        animal: self,
      });
    },
    get isFullWithFat(): boolean {
      return isFull(
        {
          ...getRoomContext(),
          player: this.owner,
          animal: self,
        },
        true,
      );
    },
    get needToEat(): number {
      return foodNeeded({
        ...getRoomContext(),
        player: this.owner,
        animal: self,
      });
    },
    get needToEatWithFat(): number {
      return foodNeeded(
        {
          ...getRoomContext(),
          player: this.owner,
          animal: self,
        },
        true,
      );
    },
  }));

export type AnimalCardModelType = Instance<typeof AnimalCardModel>;
