import {
  AnimalCard,
  ExtensionType,
} from "backend/src/common/types/ExtensionCard";

export const getAnimalDefaultName = (card: AnimalCard): string => {
  const isPredator = card.extensions.some(
    (extension) => extension.type === ExtensionType.predator,
  );
  const isSwimming = card.extensions.some(
    (extension) => extension.type === ExtensionType.swimming,
  );
  if (isPredator && isSwimming) {
    return "Хищнорыба";
  }
  if (isPredator) {
    return "Хищник";
  }
  if (isSwimming) {
    return "Рыба";
  }
  return "Животное";
};
