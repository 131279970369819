import React from "react";

export type Point = {
  x: number;
  y: number;
};

export const useRefPosition = (
  ref: React.MutableRefObject<HTMLElement | null>,
): null | Point => {
  const element = ref.current;

  if (element) {
    const rect = element.getBoundingClientRect();
    return {
      x: rect.left + rect.width / 2,
      y: rect.top,
    };
  }

  return null;
};
