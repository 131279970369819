import { useState } from "react";
import { useWindowEventListener, useWindowSize } from "rooks";
import { BackgroundRow } from "./Row";

import styles from "./styles.module.scss";

const CARD_HEIGHT = 200;
const GAP = 10;

const BackgroundRows = () => {
  const { outerHeight } = useWindowSize();
  const rowsCount = Math.ceil(outerHeight / (CARD_HEIGHT + GAP));
  const rows = Array.from({ length: rowsCount }, (_, i) => i).map((i) => {
    return (
      <BackgroundRow
        direction={i % 2 === 0 ? "left" : "right"}
        gap={GAP}
        key={i}
      />
    );
  });
  return (
    <div className={styles.rows} style={{ gap: GAP }}>
      {rows}
    </div>
  );
};

export const Background = () => {
  const [wasFocused, setWasFocused] = useState<boolean>(() =>
    document.hasFocus(),
  );
  useWindowEventListener("focus", () => setWasFocused(true));
  if (!wasFocused) {
    return null;
  }
  return <div className={styles.background}>{<BackgroundRows />}</div>;
};
