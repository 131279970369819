import { extensionCards } from "backend/src/common/gameData/extensions";
import { ExtensionType } from "backend/src/common/types/ExtensionCard";
import { ExtensionCardModelType } from "store/models/ExtensionCard";

import { AnimalCardPlaceholder } from "components/AnimalCard/AnimalCardPlaceholder";
import { Card } from "components/Card/Card";

import styles from "./styles.module.scss";
import { useHover } from "hooks/useHover";
import { ScrollYoyo } from "components/ScrollYoyo/ScrollYoyo";
import { useEffect, useRef, useState } from "react";
import { Draggable } from "gsap/Draggable";
import gsap from "gsap";
import { utilityClassNames } from "constats/utilityClassNames";
import { placeNewAnimal } from "socket/events/placeNewAnimal";
import { store } from "store/store";
import { getCardClassName } from "utils/getCardClassName";
import classNames from "classnames";
import { placeExtensionOnAnimal } from "socket/events/placeExtensionOnAnimal";
import { extensionImages } from "./images";
import { GameStage } from "backend/src/common/types/roomState";

type ExtensionCardProps = {
  card: ExtensionCardModelType;
  canBeAddedToAnimals?: boolean;
  index: number;
  onAdd?: () => void;
};

export const ExtensionCard: React.FC<ExtensionCardProps> = ({
  card,
  canBeAddedToAnimals,
  index,
  onAdd,
}) => {
  const extensionData = extensionCards[card.type];
  const [isHovered, onMouseOver, onMouseOut] = useHover();
  const [isFlipped, setIsFlipped] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const cardRef = useRef<HTMLDivElement | null>(null);
  const triggerRef = useRef<HTMLDivElement | null>(null);
  const backRef = useRef<HTMLDivElement | null>(null);
  const frontRef = useRef<HTMLDivElement | null>(null);

  const alive = useRef<boolean>(true);
  useEffect(() => {
    alive.current = true;
    return () => {
      alive.current = false;
    };
  }, []);

  const isEditingLayout = store.isEditingLayout;
  useEffect(() => {
    if (canBeAddedToAnimals) {
      const target = cardRef.current;
      const trigger = triggerRef.current;
      if (target && !isEditingLayout) {
        const draggable = new Draggable(target, {
          trigger,
          onDragStart() {
            setIsDragging(true);
            store.setDraggingSomething(true);
          },
          onDrag() {
            const opacity = gsap.getProperty(target, "opacity");
            if (![0, 1].includes(Number(opacity))) {
              return;
            }
            const isOnAnimalsList =
              store.gameState.stage === GameStage.placing &&
              Draggable.hitTest(
                trigger,
                `.${utilityClassNames.animalsList}`,
                "50%",
              );
            const animalsHovered = Array.from(
              document.querySelectorAll(
                `[data-type="${
                  extensionData.canBeAddedToEnemyAnimal
                    ? "enemy-animal-card"
                    : "animal-card"
                }"]`,
              ),
            ).filter((element) => {
              const hit = this.hitTest(element, "30%");
              if (hit) {
                element.classList.add("hovered");
              } else {
                element.classList.remove("hovered");
              }
              return hit;
            });

            setIsFlipped(isOnAnimalsList && animalsHovered.length === 0);
          },
          onDragEnd() {
            store.setDraggingSomething(false);
            document
              .querySelectorAll(
                `[data-type="${
                  extensionData.canBeAddedToEnemyAnimal
                    ? "enemy-animal-card"
                    : "animal-card"
                }"]`,
              )
              .forEach((element) => element.classList.remove("hovered"));

            setIsDragging(false);
            const isOnAnimalsList =
              store.gameState.stage === GameStage.placing &&
              this.hitTest(`.${utilityClassNames.animalsList}`, "50%");
            const someAnimalHit = Array.from(
              document.querySelectorAll(
                `[data-type="${
                  extensionData.canBeAddedToEnemyAnimal
                    ? "enemy-animal-card"
                    : "animal-card"
                }"]`,
              ),
            ).filter((element) => this.hitTest(element, "30%"));

            gsap.to(target, {
              opacity: 0.2,
              onComplete: () => {
                if (alive.current) {
                  gsap.to(target, {
                    x: this.startX,
                    y: this.startY,
                    opacity: 1,
                  });
                }
              },
            });
            if (someAnimalHit.length > 0) {
              const animal = someAnimalHit[0];
              if (extensionData.shouldBePaired) {
                if (someAnimalHit.length >= 2) {
                  const indexes = someAnimalHit.map((element) => {
                    return Number(element.getAttribute("data-index"));
                  });
                  const userIndex = Number(
                    animal.getAttribute("data-player-index"),
                  );
                  if (!isNaN(indexes[0]) && !isNaN(indexes[1])) {
                    onAdd?.();
                    placeExtensionOnAnimal(
                      index,
                      [indexes[0], indexes[1]],
                      extensionData.canBeAddedToEnemyAnimal
                        ? userIndex
                        : undefined,
                    );
                  }
                }
                return;
              }
              const animalIndex = Number(animal.getAttribute("data-index"));
              const userIndex = Number(
                animal.getAttribute("data-player-index"),
              );
              if (!isNaN(animalIndex)) {
                onAdd?.();
                placeExtensionOnAnimal(
                  index,
                  Number(animalIndex),
                  extensionData.canBeAddedToEnemyAnimal ? userIndex : undefined,
                );
                return;
              }
              gsap.to(target, { x: this.startX, y: this.startY });
              return;
            }

            if (isOnAnimalsList) {
              const extensionIndex = store.currentPlayer?.deck.findIndex(
                (playerCard) => playerCard === card,
              );
              if (extensionIndex !== undefined && extensionIndex !== -1) {
                draggable.kill();
                onAdd?.();
                placeNewAnimal(extensionIndex);
                return;
              }
            }
            gsap.to(target, { x: this.startX, y: this.startY });
          },
        });
        return () => {
          draggable.kill();
        };
      }
    }
  }, [
    canBeAddedToAnimals,
    card,
    index,
    setIsFlipped,
    extensionData.canBeAddedToEnemyAnimal,
    extensionData.shouldBePaired,
    onAdd,
    isEditingLayout,
  ]);

  useEffect(() => {
    const cardElement = frontRef.current;
    if (cardElement) {
      gsap.to(cardElement, {
        duration: 0.2,
        opacity: isFlipped ? 0 : 1,
      });
    }
  }, [isFlipped]);

  useEffect(() => {
    if (isDragging) {
      document.body.classList.add("dragging-extension");
      return () => {
        document.body.classList.remove("dragging-extension");
      };
    }
  }, [isDragging]);

  const additionalFood =
    extensionData.additionalFoodLabel ||
    (extensionData.additionalFood ? `+${extensionData.additionalFood}` : "");

  return (
    <div
      className={classNames(styles.container, {
        dragging: isDragging,
      })}
      ref={cardRef}
    >
      <div className={styles.back} ref={backRef}>
        <AnimalCardPlaceholder className={getCardClassName(card)} />
      </div>
      <div className={styles.front} ref={frontRef}>
        <Card
          title={extensionData.meta.name}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          afterTitle={additionalFood}
          red={card.visibleType === ExtensionType.predator}
          pink={extensionData.canBeAddedToEnemyAnimal}
          yellow={card.visibleType === ExtensionType.fat}
        >
          <div className={styles.cardContent}>
            {extensionImages[card.visibleType] && (
              <div className={styles.image}>
                <img src={extensionImages[card.visibleType]?.empty} />
              </div>
            )}
            <ScrollYoyo active={isHovered} className={styles.description}>
              {extensionData.meta.description}
            </ScrollYoyo>
          </div>
        </Card>
      </div>

      <div ref={triggerRef} className={styles.trigger}></div>
    </div>
  );
};
