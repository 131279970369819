import React from "react";
import { getOrCreateSocket } from "socket/socket";

export const useIsSocketConnected = (): boolean => {
  const [isSocketConnected, setIsSocketConnected] = React.useState(true);
  React.useEffect(() => {
    const socket = getOrCreateSocket();
    socket
      .on("connect", () => {
        setIsSocketConnected(true);
      })
      .on("disconnect", () => {
        setIsSocketConnected(false);
      });
  }, []);

  return isSocketConnected;
};
