import {
  AnimalCard,
  ExtensionType,
  SimplifiedExtensionCard,
} from './ExtensionCard';

export enum RoomState {
  pending = 'pending',
  playing = 'playing',
}

export enum GameStage {
  placing = 'placing',
  eating = 'eating',
  extinction = 'extinction',
  end = 'end',
}

export enum HandType {
  human = 'human',
  poc = 'poc',
  stick = 'stick',
  material = 'material',
  altushka = 'altushka',
  julia = 'julia',
  cat = 'cat',
}

export type GameState = {
  step: number;
  stage: GameStage;
  activeUser: string | '*' | 'none';
  ready: string[];
  foodOnTheTable: number;
  cardsLeft: number;
  results?: Array<{ playerId: string; score: number }>;
  additionalResults?: Array<string>;
  timePerSteps: number[];
  timePerStepsAverage?: number;
  cycleStartedAt?: number;
  cardsPerSteps: number[];
  cardsPerStepsAverage?: number;
};

export type Player = {
  id: string;
  cards: AnimalCard[];
  deck: SimplifiedExtensionCard[];
  meta: { name?: string };
  handType: HandType;
  avatarHash?: string;
  isBot: boolean;
};

export type DisabledCards = Partial<Record<ExtensionType, boolean>>;

export type RoomSettings = {
  /** Карт на пользователя в начале игры */
  cardsPerUserInitial: number;
  /** Карт на одно животное пользователя после завершения питания */
  cardsPerUserAliveAnimal: number;
  /** Диапазон количества еды в начале фазы питания */
  foodAbundance: {
    from: number;
    to: number;
  };
  /** Карт в колоде всего */
  totalCardLimit: number;
  /** Карты, которые не будут использоваться */
  disabledCards: DisabledCards;
};

export type Room<TPlayer = Player> = {
  state: RoomState;
  gameState: GameState;
  settings: RoomSettings;
  players: TPlayer[];
  commonExtensions: SimplifiedExtensionCard[];
  actions: number;
  isPublic: boolean;
};

export type SimpleRoom = {
  name: string;
  playersCount: number;
};
