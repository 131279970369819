import { Footer } from "components/Footer/Footer";
import { Header } from "components/Header/Header";
import { Page } from "components/Page/Page";
import {
  Box,
  Container,
  Content,
  Heading,
  Section,
} from "react-bulma-components";

type Block = {
  title: string;
  content: React.ReactNode;
};

const RULES_BLOCKS: Block[] = [
  {
    title: "Правила игры",
    content: `В игре «Эволюция» вам предлагается самим комбинировать различные свойства животных в условиях постоянно меняющегося количества пищевых ресурсов. Регулируя
    численность, получая новые полезные свойства и противодействуя соперникам, ваши
    животные должны выжить и к концу игры занять доминирующее положение на столе.`,
  },
  {
    title: "Условие победы",
    content: `Победителем становится игрок, получивший в конце игры наибольшее количество очков. Очки игрок получает за всех своих выживших животных и обретённые ими свойства.`,
  },
  {
    title: "Порядок хода",
    content: (
      <>
        Игра идет по ходам. Ход разделён на три фазы:
        <ol>
          <li>фаза развития</li>
          <li>фаза питания</li>
          <li>фаза вымирания и получение новых карт</li>
        </ol>
      </>
    ),
  },
  {
    title: "Фаза развития",
    content: (
      <>
        <p>
          В эту фазу хода игроки могут выкладывать карты из руки на стол. Каждая
          карта может быть сыграна либо как животное, либо как свойство (карта
          со свойством кладется на карту животного).
        </p>
        <p>
          Парные карты (например, взаимодействие) кладутся сразу на двоих
          животных.
        </p>
      </>
    ),
  },
  {
    title: "Фаза питания",
    content: ``,
  },
  {
    title: "",
    content: ``,
  },
];

export const RulesPage = () => (
  <>
    <Page>
      <Header centered />
      <Section>
        <Container>
          {RULES_BLOCKS.map((block, i) => (
            <Box key={i}>
              <Heading size={2}>{block.title}</Heading>
              <Content>{block.content}</Content>
            </Box>
          ))}
        </Container>
      </Section>
    </Page>
    <Footer />
  </>
);
