import classNames from "classnames";
import { useLayoutEffect, useRef } from "react";
import styles from "./styles.module.scss";

type CardProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  title?: string;
  afterTitle?: React.ReactNode;
  afterTitleColor?: "#6b0101" | "#8d7a00";
  cardRef?: React.MutableRefObject<HTMLDivElement | null>;
  cardClassName?: string;
  hoverable?: boolean;
  red?: boolean;
  blue?: boolean;
  pink?: boolean;
  yellow?: boolean;
  used?: boolean;
  disabled?: boolean;
  onTitleChange?: (value: string) => void;
};

export const Card: React.FC<React.PropsWithChildren<CardProps>> = ({
  children,
  title,
  afterTitle,
  afterTitleColor = "#6b0101",
  cardRef,
  cardClassName,
  hoverable,
  red,
  blue,
  pink,
  yellow,
  used,
  disabled,
  onTitleChange,
  ...containerProps
}) => {
  const titleRef = useRef<HTMLDivElement | null>(null);
  useLayoutEffect(() => {
    const titleElement = titleRef.current;
    if (titleElement && title) {
      titleElement.innerText = title;
    }
  }, [title]);
  return (
    <div
      className={classNames(styles.container, cardClassName)}
      {...containerProps}
      ref={cardRef}
    >
      <div
        className={classNames(styles.card, {
          [styles.red]: red,
          [styles.blue]: blue,
          [styles.pink]: pink,
          [styles.yellow]: yellow,
          [styles.used]: used,
          [styles.disabled]: disabled,
          [styles.hoverable]: hoverable,
        })}
      >
        <div className={styles.titleContainer}>
          {title && (
            <div
              ref={titleRef}
              className={styles.title}
              contentEditable={!!onTitleChange}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  // @ts-ignore
                  e.target.blur();
                }
              }}
              onClick={(e) => {
                if (!!onTitleChange) {
                  e.stopPropagation();
                }
              }}
              onBlur={(e) => {
                if (onTitleChange) {
                  onTitleChange(e.target.innerText);
                }
              }}
            />
          )}
          {afterTitle && (
            <div
              style={{ color: afterTitleColor }}
              className={styles.afterTitle}
            >
              {afterTitle}
            </div>
          )}
        </div>
        {children}
      </div>
    </div>
  );
};
