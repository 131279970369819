import { AnimalContext } from "backend/src/common/types/ExtensionCard";
import { AnimalCardModelType } from "store/models/AnimalCard";
import { getPlayerContext } from "./getPlayerContext";

export const getAnimalContext = (
  animal: AnimalCardModelType,
): AnimalContext => ({
  ...getPlayerContext(animal.owner),
  animal,
});
