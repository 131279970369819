import { v4 as uuidv4 } from "uuid";

let memId: string | null = null;

export const getOrCreateMyId = (): string => {
  if (memId) {
    return memId;
  }
  const savedId = localStorage.getItem("id");
  if (savedId) {
    return savedId;
  }
  const id = uuidv4();
  localStorage.setItem("id", id);
  memId = id;
  return id;
};
