import { Button, Modal } from "react-bulma-components";
import { confirmable, createConfirmation } from "react-confirm";

const Confirm = confirmable(({ show, proceed, cancel, confirmation }) => {
  return (
    <Modal show={show} closeOnBlur closeOnEsc onClose={cancel}>
      <Modal.Card>
        <Modal.Card.Header>
          <Modal.Card.Title>{confirmation}</Modal.Card.Title>
        </Modal.Card.Header>
        <Modal.Card.Footer>
          <Button color="primary" onClick={() => proceed()}>
            Да
          </Button>
          <Button onClick={() => cancel()}>Нет</Button>
        </Modal.Card.Footer>
      </Modal.Card>
    </Modal>
  );
});

const confirmation = createConfirmation(Confirm);

export const confirm = async (message: string) => {
  try {
    await confirmation({ confirmation: message });
    return true;
  } catch {
    return false;
  }
};
