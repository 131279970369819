import { extensionCards } from "backend/src/common/gameData/extensions";
import {
  ExtensionType,
  SimplifiedExtensionCard,
} from "backend/src/common/types/ExtensionCard";
import { ExtensionCardModelType } from "store/models/ExtensionCard";

import { Card } from "components/Card/Card";

import styles from "./styles.module.scss";
import { useHover } from "hooks/useHover";
import { ScrollYoyo } from "components/ScrollYoyo/ScrollYoyo";
import { extensionImages } from "./images";
import { canExtensionBeUsed } from "backend/src/common/utils/canExtensionBeUsed";
import { store } from "store/store";
import { GameState } from "backend/src/common/types/roomState";
import { useExtension } from "socket/events/useExtension";
import { getExtensionAdditionalFood } from "backend/src/common/utils/getExtensionAdditionalFood";
import { getExtensionContext } from "utils/context/getExtensionContext";

type ExtensionCardSimpleProps = {
  animalIndex?: number;
  extensionIndex?: number;
  isFull?: boolean;
  other?: boolean;
  onUse?: () => void;
  card: ExtensionCardModelType;
};

export const ExtensionCardSimple: React.FC<ExtensionCardSimpleProps> = ({
  card,
  animalIndex,
  extensionIndex,
  other,
  onUse,
  isFull = false,
}) => {
  const extensionData = extensionCards[card.visibleType];
  const [isHovered, onMouseOver, onMouseOut] = useHover();

  let afterTitle = "";
  const extensionFood = getExtensionAdditionalFood(getExtensionContext(card));
  if (extensionFood) {
    afterTitle = `+${extensionFood}`;
  }
  if (card.data?.storedFood) {
    afterTitle = `${card.data.storedFood}`;
  }

  return (
    <Card
      title={extensionData.meta.name}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      hoverable
      afterTitle={afterTitle}
      afterTitleColor={card.data?.storedFood ? "#8d7a00" : "#6b0101"}
      red={card.visibleType === ExtensionType.predator}
      pink={extensionData.canBeAddedToEnemyAnimal}
      yellow={card.visibleType === ExtensionType.fat}
      used={card.wasUsedInCurrentStage}
      disabled={card.isDisabledByTumor}
    >
      <div className={styles.cardContent}>
        {extensionImages[card.visibleType] && (
          <div className={styles.image}>
            <img
              src={
                extensionImages[card.visibleType]?.[isFull ? "full" : "empty"]
              }
            />
          </div>
        )}
        <ScrollYoyo active={isHovered} className={styles.description}>
          {extensionData.meta.description}
        </ScrollYoyo>
        {!other &&
          typeof animalIndex !== "undefined" &&
          typeof extensionIndex !== "undefined" &&
          !!extensionData.onUse && (
            <button
              className={styles.use}
              onClick={() => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                useExtension(animalIndex, extensionIndex);
                onUse?.();
              }}
              disabled={
                !store.iAmActiveUser ||
                !canExtensionBeUsed(
                  store.gameState as GameState,
                  card as SimplifiedExtensionCard,
                )
              }
            >
              Использовать
            </button>
          )}
        {card.wasPlacedByUser && extensionData.canBeAddedToEnemyAnimal && (
          <div className={styles.wasPlacedByUser}>
            Подарок от{" "}
            {store.players.find((player) => player.id === card.wasPlacedByUser)
              ?.meta.name || card.wasPlacedByUser}
          </div>
        )}
      </div>
    </Card>
  );
};
