import { ExtensionContext } from "backend/src/common/types/ExtensionCard";
import { AnimalCardModelType } from "store/models/AnimalCard";
import { ExtensionCardModelType } from "store/models/ExtensionCard";
import { getAnimalContext } from "./getAnimalContext";

export const getExtensionContext = (
  extension: ExtensionCardModelType,
): ExtensionContext => ({
  ...getAnimalContext(extension.animal as AnimalCardModelType),
  extension,
});
