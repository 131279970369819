import classNames from "classnames";
import { Tag } from "react-bulma-components";
import styles from "./styles.module.scss";

type ExtensionTagProps = {
  title: string;
  afterTitle?: React.ReactNode;
  afterTitleColor?: "#6b0101" | "#8d7a00";
  red?: boolean;
  blue?: boolean;
  pink?: boolean;
  yellow?: boolean;
  used?: boolean;
  disabled?: boolean;
};

export const ExtensionTag: React.FC<ExtensionTagProps> = ({
  title,
  afterTitle,
  red,
  afterTitleColor = "#6b0101",
  blue,
  pink,
  used,
  disabled,
  yellow,
}) => {
  return (
    <Tag
      className={classNames(styles.tag, {
        [styles.red]: red,
        [styles.blue]: blue,
        [styles.pink]: pink,
        [styles.yellow]: yellow,
        [styles.used]: used,
        [styles.disabled]: disabled,
      })}
      rounded
    >
      {title}
      {afterTitle && (
        <span style={{ color: afterTitleColor }}>&nbsp;{afterTitle}</span>
      )}
    </Tag>
  );
};
