import ReactDOM from "react-dom";
import { faExpand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GameStage } from "backend/src/common/types/roomState";
import classNames from "classnames";
import { AnimalCard } from "components/AnimalCard/AnimalCard";
import { utilityClassNames } from "constats/utilityClassNames";
import gsap from "gsap";
import { useSize } from "hooks/useSize";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { Message, Tag } from "react-bulma-components";
import { PlayerModelType } from "store/models/Player";
import { store } from "store/store";

import styles from "./styles.module.scss";
import { getPlayerAvatar } from "utils/getPlayerAvatar";
import { iAmNotReady } from "socket/events/iAmNotReady";

type AnimalProps = {
  player: PlayerModelType;
  playerIndex: number;
  other?: boolean;
  onFullscreenCancel?: () => void;
};

const roundTo = (num: number, precision: number) => {
  return Math.round(num * precision) / precision;
};

export const Animals: React.FC<AnimalProps> = observer(
  ({ player, other, playerIndex, onFullscreenCancel }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const animalsRef = useRef<HTMLDivElement>(null);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [zoom, setZoom] = useState<number>(1);
    const [maxZoom, setMaxZoom] = useState<number>(1);
    const [gsapX, setGsapX] = useState<number>(1);
    const [isZooming, setIsZooming] = useState<boolean>(false);
    const gameStage = store.gameState.stage;
    const playersCount = store.players.length;
    const animalsSize = useSize(animalsRef);
    const containerSize = useSize(containerRef);
    const isEditingLayout = store.isEditingLayout;
    const direction =
      containerSize.height > containerSize.width ? "vertical" : "horizontal";
    useEffect(() => {
      const animalsElement = animalsRef.current;
      const containerElement = containerRef.current;
      if (animalsElement && containerElement && !onFullscreenCancel) {
        const containerWidth = containerSize.width;
        const containerHeight = containerSize.height;
        const animalsWidth = animalsElement.scrollWidth;
        const animalsHeight = animalsElement.scrollHeight;
        const scaleX = containerWidth / animalsWidth;
        const scaleY = containerHeight / animalsHeight;
        const scale = Math.min(scaleX, scaleY);
        const autoScale = scale * scale;
        setZoom(autoScale);
        setMaxZoom(autoScale);
      }
    }, [
      onFullscreenCancel,
      player.cards.length,
      gameStage,
      playersCount,
      containerSize.height,
      containerSize.width,
      animalsSize.width,
      animalsSize.height,
    ]);
    useEffect(() => {
      setIsZooming(true);
      const gsapAction = gsap.to(animalsRef.current, {
        scale: zoom,
        duration: 0.1,
        onUpdate: () => {
          setGsapX(Number(gsap.getProperty(animalsRef.current, "x")));
        },
        onComplete: () => {
          setIsZooming(false);
        },
      });
      return () => {
        gsapAction.kill();
      };
    }, [zoom]);

    return (
      <>
        {isFullScreen &&
          ReactDOM.createPortal(
            <Animals
              player={player}
              other={other}
              playerIndex={playerIndex}
              onFullscreenCancel={() => {
                setIsFullScreen(false);
              }}
            />,
            document.body,
          )}

        <Message
          className={classNames({
            [styles.isRenderedAsFullScreen]: !!onFullscreenCancel,
          })}
          size="small"
          color={player.id === store.gameState.activeUser ? "primary" : "dark"}
        >
          <Message.Header>
            <div>
              {!other && (
                <>
                  <Tag color="info" className={styles.tag}>
                    Вы
                  </Tag>
                </>
              )}
              {player.isBot && <Tag className={styles.tag}>🤖</Tag>}
              <Tag className={styles.tag}>
                {player.meta.name || "Без имени"}
              </Tag>
            </div>
            <span>
              {store.gameState.ready.includes(player.id) && (
                <Tag
                  color="success"
                  onClick={() => {
                    if (!other) {
                      iAmNotReady();
                    }
                  }}
                >
                  Готов
                </Tag>
              )}
            </span>
          </Message.Header>
          <Message.Body
            onWheel={(e: WheelEvent) => {
              setZoom(
                roundTo(
                  Math.min(
                    maxZoom,
                    Math.max(
                      0.1,
                      Math.min(zoom + (e.deltaY < 0 ? 0.1 : -0.1), 1),
                    ),
                  ),
                  10,
                ),
              );
            }}
            className={classNames(styles.container, {
              [styles.noEvents]: isEditingLayout,
              [styles.active]: player.id === store.gameState.activeUser,
            })}
            // @ts-ignore
            domRef={containerRef}
            renderAs="div"
          >
            <img className={styles.image} src={getPlayerAvatar(player)} />
            <div
              className={classNames(styles.controls, {
                [styles.horizontal]: containerSize.height <= 140,
              })}
            >
              <button
                className={styles.button}
                onClick={() => {
                  if (onFullscreenCancel) {
                    onFullscreenCancel();
                  } else {
                    setIsFullScreen(!isFullScreen);
                  }
                }}
              >
                <FontAwesomeIcon icon={faExpand} />
              </button>
              <button
                className={styles.button}
                onClick={() => {
                  setZoom(
                    roundTo(Math.min(maxZoom, Math.min(zoom + 0.1, 1)), 10),
                  );
                }}
              >
                +
              </button>
              <button
                className={styles.button}
                onClick={() => {
                  setZoom(roundTo(Math.max(zoom - 0.1, 0.1), 10));
                }}
              >
                -
              </button>
            </div>
            <div
              className={classNames(styles.animals, styles[direction])}
              ref={animalsRef}
            >
              {player.cards.map((card, index) => (
                <div key={card.id}>
                  <AnimalCard
                    other={other}
                    card={card}
                    index={index}
                    playerIndex={playerIndex}
                    isZooming={isZooming}
                    x={gsapX}
                  />
                </div>
              ))}
              {!other && store.gameState.stage === GameStage.placing && (
                <div className={styles.dropZoneContainer}>
                  <div
                    className={classNames(
                      styles.dropZone,
                      utilityClassNames.animalsList,
                    )}
                  >
                    +
                  </div>
                </div>
              )}
            </div>
          </Message.Body>
        </Message>
      </>
    );
  },
);
