import { GameStage } from "backend/src/common/types/roomState";
import { types } from "mobx-state-tree";

export const GameStateModel = types.model({
  step: types.optional(types.number, 0),
  stage: types.optional(
    types.enumeration<GameStage>(Object.values(GameStage)),
    GameStage.placing,
  ),
  activeUser: types.optional(types.string, ""),
  ready: types.optional(types.array(types.string), []),
  foodOnTheTable: types.optional(types.number, 0),
  cardsLeft: types.optional(types.number, 0),
  results: types.optional(
    types.array(
      types.model({
        playerId: types.string,
        score: types.number,
      }),
    ),
    [],
  ),
  additionalResults: types.optional(types.array(types.string), []),
  cardsPerSteps: types.optional(types.array(types.number), []),
  cardsPerStepsAverage: types.optional(types.number, 0),
  timePerSteps: types.optional(types.array(types.number), []),
  timePerStepsAverage: types.optional(types.number, 0),
  cycleStartedAt: types.optional(types.number, 0),
});
