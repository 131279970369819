import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { Button } from "react-bulma-components";
import { iAmNotReady } from "socket/events/iAmNotReady";
import { iAmReady } from "socket/events/iAmReady";
import { store } from "store/store";
import styles from "./styles.module.scss";

export const ReadyButton = observer(() => {
  const readyButton = (
    <Button
      size="medium"
      color="primary"
      onClick={() => {
        iAmReady();
      }}
    >
      Готов
    </Button>
  );
  const notReadyButton = (
    <Button
      size="medium"
      color="warning"
      onClick={() => {
        iAmNotReady();
      }}
    >
      Не готов
    </Button>
  );
  return (
    <div
      className={classNames(styles.button, {
        [styles.flip]: store.iAmReady,
        [styles.disabled]: !store.iAmReady && !store.iAmActiveUser,
      })}
    >
      <div className={styles.ready}>{readyButton}</div>
      <div className={styles.notReady}>{notReadyButton}</div>
    </div>
  );
});
