import { ExtensionType } from "backend/src/common/types/ExtensionCard";
import { getOrCreateSocket } from "socket/socket";
import { store } from "store/store";

// eslint-disable-next-line @typescript-eslint/naming-convention
export const debug_addExtension = (types: ExtensionType | ExtensionType[]) => {
  const socket = getOrCreateSocket();
  if (!Array.isArray(types)) {
    types = [types];
  }
  types.forEach((type) => {
    socket.emit("debug_addExtension", store.currentRoom, type);
  });
};
