import classNames from "classnames";
import { ExtensionIcon } from "components/ExtensionCard/ExtensionIcon";
import { observer } from "mobx-react-lite";
import { store } from "store/store";
import { FoodItem } from "./FoodItem";
import styles from "./styles.module.scss";

export const FoodBase = observer(() => {
  const foodCount = store.gameState.foodOnTheTable || 0;
  return (
    <div
      key={foodCount}
      className={classNames(styles.foodBase, {
        [styles.active]: store.iAmActiveUser,
      })}
    >
      {new Array(foodCount).fill(null).map((_, index) => {
        return <FoodItem key={index} active={store.iAmActiveUser} />;
      })}
      {store.commonExtensions.slice(0, 4).map((extension, index) => (
        <div className={styles.extension} key={extension.id}>
          <ExtensionIcon
            canBeAddedToAnimals
            card={extension}
            index={index}
            icon={<>🐚</>}
          />
        </div>
      ))}
    </div>
  );
});
