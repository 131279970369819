import { STAGES_NAMES } from "backend/src/common/gameData/stages";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Heading } from "react-bulma-components";
import ReactDOM from "react-dom";
import { usePreviousImmediate, useTimeoutWhen } from "rooks";
import { store } from "store/store";
import styles from "./styles.module.scss";

const SPLASH_VISIBLE_TIME = 2000;

export const SplashScreen = observer(() => {
  const [isSplashScreenVisible, setIsSplashScreenVisible] =
    useState<boolean>(false);
  const [willBeSplashScreenHidden, setWillBeSplashScreenHidden] =
    useState<boolean>(false);
  const currentStage = store.gameState.stage;
  const previousStage = usePreviousImmediate(currentStage);
  useTimeoutWhen(
    () => {
      setIsSplashScreenVisible(false);
    },
    SPLASH_VISIBLE_TIME,
    isSplashScreenVisible,
  );
  useTimeoutWhen(
    () => {
      setWillBeSplashScreenHidden(true);
    },
    SPLASH_VISIBLE_TIME - 800,
    isSplashScreenVisible,
  );
  useEffect(() => {
    if (previousStage !== currentStage) {
      setIsSplashScreenVisible(true);
      setWillBeSplashScreenHidden(false);
    }
  }, [currentStage, previousStage]);
  if (!isSplashScreenVisible) {
    return null;
  }
  const stageName = STAGES_NAMES[currentStage];
  return ReactDOM.createPortal(
    <div
      className={classNames(styles.screen, "animate__animated", {
        animate__fadeOut: willBeSplashScreenHidden,
      })}
    >
      <Heading
        className="animate__animated animate__fadeIn"
        size={6}
        weight="normal"
      >
        Начинается
      </Heading>
      <Heading className="animate__animated animate__fadeIn animate__slow">
        {stageName}
      </Heading>
    </div>,
    document.body,
  );
});
