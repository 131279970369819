import { GameStage, RoomState } from "backend/src/common/types/roomState";
import { getOrCreateSocket } from "socket/socket";
import { store } from "store/store";

export const eatPlant = (animalCardIndex: number) => {
  const socket = getOrCreateSocket();
  if (
    store.currentRoom &&
    store.roomState === RoomState.playing &&
    store.iAmActiveUser &&
    store.gameState.stage === GameStage.eating &&
    store.currentPlayer?.cards[animalCardIndex]
  ) {
    socket.emit("eatPlant", store.currentRoom, animalCardIndex);
  }
};
