import chalk, { ForegroundColor } from 'chalk';
import debug from 'debug';
import { Player, Room } from '../common/types/roomState';
import { LogTypes } from '../common/types/socket';
import { BackendSidePlayer } from './room';

const logsDebug = debug('ev:logs');

const TYPE_COLORS: Record<LogTypes, ForegroundColor> = {
  info: 'green',
  error: 'red',
  warning: 'yellow',
  success: 'green',
  default: 'blue',
};

export const logToRoom = (
  room: Room,
  message: string,
  type: LogTypes = 'default',
) => {
  room.players.forEach((player) => {
    logToPlayer(player, message, type);
  });
};

export const logToPlayer = (
  player: BackendSidePlayer | Player,
  message: string,
  type: LogTypes = 'default',
) => {
  const bPlayer = player as BackendSidePlayer;
  if (bPlayer.socket) {
    logsDebug(
      `Log to ${chalk.blue(bPlayer.meta.name || 'unknown')} ${chalk[
        TYPE_COLORS[type]
      ](type)} "${message}"`,
    );
    try {
      bPlayer.socket.emit('log', message, type);
    } catch (e) {
      logsDebug(e);
    }
  }
};
