import classNames from "classnames";
import { ExtensionCard } from "components/ExtensionCard/ExtensionCard";
import gsap from "gsap";
import { useSize } from "hooks/useSize";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { iAmReady } from "socket/events/iAmReady";
import { PlayerModelType } from "store/models/Player";
import { store } from "store/store";
import { Hand } from "./hands/Hand";

import styles from "./styles.module.scss";

const TOTAL_SECTOR_ANGLE = 20;
const BASE_WIDTH = 1600;
const FIRST_CARD_ANGLE = -TOTAL_SECTOR_ANGLE / 2;

export const Deck: React.FC = observer(() => {
  const placing = store.isPlacing;
  const currentPlayer = store.currentPlayer as PlayerModelType;
  const cardsCount = currentPlayer.deck.length;
  const anglePerCard = TOTAL_SECTOR_ANGLE / (cardsCount - 1);
  const [cardWasPlaced, setCardWasPlaced] = useState(false);
  const isEditingLayout = store.isEditingLayout;

  useEffect(() => {
    if (placing && cardsCount === 0 && cardWasPlaced) {
      iAmReady();
      setCardWasPlaced(false);
    }
  }, [placing, cardsCount, cardWasPlaced]);

  const containerRef = useRef<null | HTMLDivElement>(null);
  const minimalAmount = useRef<number>(cardsCount);
  useEffect(() => {
    if (minimalAmount.current > cardsCount) {
      minimalAmount.current = cardsCount;
    }
  }, [cardsCount]);
  useEffect(() => {
    const listener = () => (minimalAmount.current = cardsCount);
    const container = containerRef.current;
    if (container) {
      container.addEventListener("animationend", listener);
      return () => container.removeEventListener("animationend", listener);
    }
  }, [cardsCount]);

  const onAdd = useCallback(() => setCardWasPlaced(true), []);

  const cardsRef = useRef<null | HTMLDivElement>(null);

  const cardsSize = useSize(cardsRef);
  const containerSize = useSize(containerRef);

  const autoZoom = useMemo(() => {
    const container = containerRef.current;
    const cards = cardsRef.current;
    if (!container || !cards) {
      return 1;
    }
    const containerHeight = container.offsetHeight;
    const cardsHeight = cards.scrollHeight;
    return containerHeight / cardsHeight;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cardsSize.height,
    cardsSize.width,
    containerSize.height,
    containerSize.width,
  ]);

  useEffect(() => {
    gsap.set(cardsRef.current, {
      scale: autoZoom,
    });
  }, [autoZoom]);

  return (
    <div
      className={classNames(styles.deck, {
        [styles.noEvents]: isEditingLayout,
      })}
      ref={containerRef}
    >
      <div className={styles.cards} ref={cardsRef}>
        {currentPlayer.deck.length > 1 && (
          <Hand type={currentPlayer.handType} left />
        )}
        {currentPlayer.deck.map((card, index) => {
          const angle = FIRST_CARD_ANGLE + anglePerCard * index;
          const height =
            -BASE_WIDTH * Math.cos(Math.abs(angle) * (Math.PI / 180)) +
            BASE_WIDTH;
          const isNew = minimalAmount.current < index + 1;
          return (
            <div
              key={card.id}
              className={styles.card}
              style={{
                transform: `rotate(${angle}deg) translate(0, ${height}px)`,
              }}
            >
              <div
                className={classNames(styles.cardContent, {
                  "animate__animated animate__fadeInDown": isNew,
                })}
              >
                <ExtensionCard
                  onAdd={onAdd}
                  canBeAddedToAnimals
                  card={card}
                  index={index}
                />
              </div>
            </div>
          );
        })}
        {currentPlayer.deck.length > 0 && (
          <Hand type={currentPlayer.handType} />
        )}
      </div>
    </div>
  );
});
