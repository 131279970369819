import { AnimalContext } from '../types/ExtensionCard';
import { foodNeeded } from './foodNeeded';

export const isFull = (
  context: AnimalContext,
  withAdditionalFood?: boolean,
): boolean => {
  const foodWithoutAdditional = foodNeeded(context, false);
  const animalSatiety = Math.min(foodWithoutAdditional, context.animal.satiety);
  const satiety = withAdditionalFood
    ? animalSatiety +
      context.animal.extensions.reduce((acc, extension) => {
        if (extension.data?.storedFood) {
          return acc + extension.data.storedFood;
        }
        return acc;
      }, 0)
    : context.animal.satiety;
  const food = foodNeeded(context, withAdditionalFood);
  return food <= satiety;
};
