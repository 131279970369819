import { HandType } from "backend/src/common/types/roomState";
import { Hand } from "components/Deck/hands/Hand";
import { Modal } from "react-bulma-components";
import { setHandType } from "socket/events/setHandType";

import { store } from "store/store";

type ModalpsProps = {
  activeps: boolean;
  setActiveps: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CustomModalps: React.FC<ModalpsProps> = ({
  activeps,
  setActiveps,
}) => {
  return (
    <Modal show={activeps} onClose={() => setActiveps(false)}>
      <Modal.Card>
        <Modal.Card.Header>
          <Modal.Card.Title>Личные настройки</Modal.Card.Title>
        </Modal.Card.Header>

        <Modal.Card.Body>
          {store.currentPlayer?.handType && (
            <div>
              {Object.values(HandType).map((type) => (
                <div key={type}>
                  <label>
                    <input
                      type="radio"
                      checked={type === store.currentPlayer?.handType}
                      onChange={() => {
                        setHandType(type);
                      }}
                      value={type}
                    />
                    {type}
                  </label>
                </div>
              ))}
              {store.currentPlayer?.handType && (
                <div
                  style={{
                    display: "flex",
                    padding: 20,
                    marginLeft: 50,
                  }}
                >
                  <Hand withCard type={store.currentPlayer.handType} />
                </div>
              )}
            </div>
          )}
        </Modal.Card.Body>
      </Modal.Card>
    </Modal>
  );
};
