import { ExtensionType } from "backend/src/common/types/ExtensionCard";

import running from "./running.png";
import big from "./big.png";
import fat from "./fat.png";
import parasite from "./parasite.png";
import poisonous from "./poisonous.png";
import immunity from "./immunity.png";
import burrowingFull from "./burrowingFull.png";
import burrowingEmpty from "./burrowingEmpty.png";

export const extensionImages: Partial<
  Record<
    ExtensionType | string,
    {
      full: string;
      empty: string;
    }
  >
> = {
  [ExtensionType.burrowing]: {
    full: burrowingFull,
    empty: burrowingEmpty,
  },
  [ExtensionType.fat]: {
    full: fat,
    empty: fat,
  },
  [ExtensionType.immunity]: {
    full: immunity,
    empty: immunity,
  },
  [ExtensionType.running]: {
    full: running,
    empty: running,
  },
  [ExtensionType.big]: {
    full: big,
    empty: big,
  },
  [ExtensionType.parasite]: {
    full: parasite,
    empty: parasite,
  },
  [ExtensionType.poisonous]: {
    full: poisonous,
    empty: poisonous,
  },
  [ExtensionType.pseudoPoisonous]: {
    full: poisonous,
    empty: poisonous,
  },
};
