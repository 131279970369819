import {
  AnimalCard,
  SimplifiedExtensionCard,
} from "backend/src/common/types/ExtensionCard";
import { AnimalCardModelType } from "store/models/AnimalCard";
import { ExtensionCardModelType } from "store/models/ExtensionCard";

export const getCardClassName = (
  card:
    | AnimalCard
    | SimplifiedExtensionCard
    | AnimalCardModelType
    | ExtensionCardModelType,
) => `card_${(card as AnimalCard)?.fromId || card.id}`;
