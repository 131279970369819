import { useState } from "react";
import { Navbar } from "react-bulma-components";
import { Link, useLocation } from "wouter";

const MENU = [
  {
    name: "Главная",
    path: "/",
  },
  {
    name: "Правила игры",
    path: "/rules",
  },
];

export const Footer = () => {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [location] = useLocation();
  return (
    <Navbar active={isMenuActive} color="dark">
      <Navbar.Brand>
        <Navbar.Burger onClick={() => setIsMenuActive(!isMenuActive)} />
      </Navbar.Brand>
      <Navbar.Menu>
        <Navbar.Container align="left">
          {MENU.map(({ name, path }) => {
            return (
              <Navbar.Item
                key={path}
                active={location === path}
                href={path}
                renderAs={Link}
              >
                {name}
              </Navbar.Item>
            );
          })}
        </Navbar.Container>
      </Navbar.Menu>
    </Navbar>
  );
};
