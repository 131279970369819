import { useEffect, useState } from "react";
import { Button, Heading } from "react-bulma-components";
import { getOrCreateSocket } from "socket/socket";
import styles from "./styles.module.scss";

export const FSLoader = () => {
  const [showReconnectButton, setShowReconnectButton] = useState(false);
  useEffect(() => {
    if (!showReconnectButton) {
      const timeout = setTimeout(() => {
        setShowReconnectButton(true);
      }, 5000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showReconnectButton]);
  return (
    <div className={styles.container}>
      <Heading size={5} weight="normal">
        Ждем соединения...
      </Heading>
      <span className={styles.loader}></span>
      {showReconnectButton && (
        <Button
          onClick={() => {
            const socket = getOrCreateSocket();
            socket.connect();
            setShowReconnectButton(false);
          }}
        >
          Попробовать ещё раз
        </Button>
      )}
    </div>
  );
};
