import packageJSON from "../package.json";
import { Route, Switch } from "wouter";
import { ModalPortal } from "components/AnimalModal/ModalPortal";
import { Game } from "./components/Game/Game";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { AdminPage } from "components/AdminPage/AdminPage";
import { RulesPage } from "components/RulesPage/RulesPage";

function App() {
  useEffect(() => {
    document.title = `Evolution v${packageJSON.version}`;
  }, []);
  return (
    <Switch>
      <Route path="/admin">
        <div>
          <AdminPage />
          <ToastContainer />
        </div>
      </Route>
      <Route path="/rules" component={RulesPage} />
      <Route path="/">
        <div>
          <Game />
          <ModalPortal />
          <ToastContainer />
        </div>
      </Route>
    </Switch>
  );
}

export default App;
