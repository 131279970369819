import { faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { extensionCards } from "backend/src/common/gameData/extensions";
import { ExtensionType } from "backend/src/common/types/ExtensionCard";
import { useState } from "react";
import { Button, Form, Modal, Navbar } from "react-bulma-components";
import { debug_addExtension } from "socket/events/debug_addExtension";
import { debug_addFood } from "socket/events/debug_addFood";
import { store } from "store/store";

export const Devtools = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Modal show={show} onClose={() => setShow(false)} closeOnEsc closeOnBlur>
        <Modal.Card>
          <Modal.Card.Header onClose={() => setShow(false)}>
            <Modal.Card.Title>DevTools</Modal.Card.Title>
          </Modal.Card.Header>
          <Modal.Card.Body>
            {Object.values(ExtensionType).map((type) => (
              <Form.Field key={type}>
                <Button.Group hasAddons>
                  <Button
                    color="primary"
                    onClick={() => {
                      debug_addExtension(type);
                    }}
                  >
                    {extensionCards[type].meta.name}
                  </Button>
                  {[3, 5, 10].map((amount) => (
                    <Button
                      key={amount}
                      onClick={() => {
                        debug_addExtension(new Array(amount).fill(type));
                      }}
                    >
                      x{amount}
                    </Button>
                  ))}
                </Button.Group>
              </Form.Field>
            ))}
            <div>
              <hr />
            </div>
            {[1, 3, 5].map((count) => (
              <Button.Group hasAddons key={count}>
                <Button
                  color="primary"
                  onClick={() => {
                    debug_addFood(count);
                  }}
                >
                  + {count} food
                </Button>
                <Button
                  color="danger"
                  onClick={() => {
                    debug_addFood(-count);
                  }}
                >
                  - {count} food
                </Button>
              </Button.Group>
            ))}
            <div>
              <hr />
            </div>
            <div>
              <Button
                color="danger"
                onClick={() => {
                  debug_addFood(-store.gameState.foodOnTheTable);
                }}
              >
                delete all food
              </Button>
            </div>
          </Modal.Card.Body>
        </Modal.Card>
      </Modal>
      <Navbar.Item
        onClick={() => {
          setShow(!show);
        }}
      >
        <FontAwesomeIcon icon={faGear} />
      </Navbar.Item>
    </>
  );
};
