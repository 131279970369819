import { applySocketSubscrptions } from "socket/subscriptions";
import { StoreModel } from "./models/Store";

export const store = StoreModel.create({
  players: [],
  gameState: {},
  settings: {
    foodAbundance: {},
    disabledCards: {},
  },
  isPublic: false,
});

// @ts-ignore
window.store = store;

applySocketSubscrptions();
