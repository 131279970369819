import { RoomContext } from "backend/src/common/types/ExtensionCard";
import { store } from "store/store";

export const getRoomContext = (): RoomContext => ({
  room: {
    state: store.roomState,
    gameState: store.gameState,
    settings: store.settings,
    players: store.players,
    commonExtensions: store.commonExtensions,
    actions: store.actions,
    isPublic: store.isPublic,
  },
});
