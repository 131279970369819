import { extensionCards } from '../gameData/extensions';
import { ExtensionContext } from '../types/ExtensionCard';

export const getExtensionAdditionalFood = (
  context: ExtensionContext,
): number => {
  const extensionData = extensionCards[context.extension.type];
  if (extensionData.customAdditionalFood) {
    return extensionData.customAdditionalFood(context);
  }
  return extensionData.additionalFood;
};
