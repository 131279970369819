import { extensionCards } from '../common/gameData/extensions';
import {
  AnimalCard,
  AnimalContext,
  ExtensionContext,
} from '../common/types/ExtensionCard';
import { getWorkingExtensions } from '../common/utils/getWorkingExtensions';

export const deletePairedExtension = (context: ExtensionContext) => {
  if (context.extension.pairWithAnimal) {
    context.player.cards.some((card) => {
      if (card.id === context.extension.pairWithAnimal) {
        const selfCloneIndex = card.extensions.findIndex(
          (extension) => extension.id === context.extension.id,
        );
        if (selfCloneIndex !== -1) {
          card.extensions.splice(selfCloneIndex, 1);
        }
      }
    });
  }
};

export const processAnimalDeath = (
  context: AnimalContext,
  killedBy?: AnimalCard,
) => {
  context.animal.extensions.forEach((extension) => {
    deletePairedExtension({
      ...context,
      extension,
    });
  });

  getWorkingExtensions(context.animal).forEach((extension) => {
    const extensionData = extensionCards[extension.type];
    if (killedBy && extensionData.afterBeingKilled) {
      extensionData.afterBeingKilled(killedBy, context.animal, context.player);
    }
    if (extensionData.onSelfDeath) {
      extensionData.onSelfDeath({
        ...context,
        extension,
      });
    }
  });
};
