import ReactDOM from "react-dom";
import styles from "./styles.module.scss";

type Point = {
  x: number;
  y: number;
};

export type ConnectionProps = {
  from: Point;
  to: Point;
  outer?: number;
  label: string;
};

type LineProps = {
  from: Point;
  to: Point;
};

const Line: React.FC<LineProps> = ({ from, to }) => {
  let startPoint;
  let endPoint;
  let isVertical = false;
  const horizontalDiff = Math.abs(from.x - to.x);
  const verticalDiff = Math.abs(from.y - to.y);

  if (verticalDiff > horizontalDiff) {
    const topPoint = from.y < to.y ? from : to;
    const bottomPoint = from.y < to.y ? to : from;
    isVertical = true;
    startPoint = topPoint;
    endPoint = bottomPoint;
  } else {
    const leftPoint = from.x < to.x ? from : to;
    const rightPoint = from.x < to.x ? to : from;
    startPoint = leftPoint;
    endPoint = rightPoint;
  }

  const lengthKey = isVertical ? "height" : "width";

  const leftX = Math.min(startPoint.x, endPoint.x);
  const leftY = Math.min(startPoint.y, endPoint.y);
  const rightX = Math.max(startPoint.x, endPoint.x);
  const rightY = Math.max(startPoint.y, endPoint.y);

  const length = Math.sqrt(
    Math.pow(rightX - leftX, 2) + Math.pow(rightY - leftY, 2),
  );

  return (
    <div
      className={styles.line}
      style={{
        transform: `translate(${startPoint.x}px, ${startPoint.y}px)`,
        [lengthKey]: length,
      }}
    />
  );
};

const DEFAULT_OFFSET = 3;
const CONNECTION_HEIGHT = 20;

export const Connection: React.FC<ConnectionProps> = ({
  from,
  to,
  outer = 0,
  label,
}) => {
  const actualHeight = CONNECTION_HEIGHT + outer * CONNECTION_HEIGHT;
  const topPoint = Math.min(from.y, to.y);
  const horizontalOffset = outer * 10;
  const parts: LineProps[] = [
    {
      from: { x: from.x - horizontalOffset + DEFAULT_OFFSET, y: from.y },
      to: {
        x: from.x - horizontalOffset + DEFAULT_OFFSET,
        y: topPoint - actualHeight,
      },
    },
    {
      from: {
        x: from.x - horizontalOffset + DEFAULT_OFFSET,
        y: topPoint - actualHeight,
      },
      to: {
        x: to.x + horizontalOffset - DEFAULT_OFFSET,
        y: topPoint - actualHeight,
      },
    },
    {
      from: {
        x: to.x + horizontalOffset - DEFAULT_OFFSET,
        y: topPoint - actualHeight,
      },
      to,
    },
  ];
  return ReactDOM.createPortal(
    <>
      <div
        className={styles.label}
        style={{
          transform: `translate(${
            Math.min(parts[1].to.x, parts[1].from.x) +
            Math.abs(parts[1].to.x - parts[1].from.x) / 2
          }px, ${parts[1].from.y}px)`,
        }}
      >
        {label}
      </div>
      {parts.map((part, index) => (
        <Line key={index} {...part} />
      ))}
    </>,
    document.body,
  );
};
