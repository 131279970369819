import { RoomState, GameStage } from "backend/src/common/types/roomState";
import { FSLoader } from "components/FSLoader/FSLoader";
import { Results } from "components/Results/Results";
import { useIsSocketConnected } from "hooks/useIsSocketConnected";
import { observer } from "mobx-react-lite";
import { store } from "store/store";
import { CurrentPlayer } from "../CurrentPlayer/CurrentPlayer";
import { StartGame } from "../StartGame/StartGame";
import { WaitingRoom } from "../WaitingRoom/WaitingRoom";

export const Game = observer(() => {
  const isSocketConnected = useIsSocketConnected();

  if (!isSocketConnected) {
    return <FSLoader />;
  }

  if (!store.currentRoom) {
    return <StartGame />;
  }

  if (store.roomState !== RoomState.playing) {
    return <WaitingRoom />;
  }

  if (store.gameState.stage === GameStage.end) {
    return <Results />;
  }

  return <>{store.currentPlayer && <CurrentPlayer />}</>;
});
