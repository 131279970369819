import { useEffect, useState } from "react";

type Getter<T> = () => T;

export const useStoredState = <T>(
  key: string,
  defaultState: T | Getter<T>,
): [T, React.Dispatch<T>, (value: T) => void] => {
  const [state, setState] = useState(() => {
    try {
      const stored = localStorage.getItem(key);
      if (stored) {
        const parsed = JSON.parse(stored);
        return parsed;
      }
    } catch {
      // ignore
    }
    if (defaultState) {
      if (typeof defaultState === "function") {
        return (defaultState as Getter<T>)();
      }
      return defaultState;
    }
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [
    state,
    setState,
    (value) => localStorage.setItem(key, JSON.stringify(value)),
  ];
};
