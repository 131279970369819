type PluralizeType = "age";

type PluralizeTitles = [string, string, string];

const declOfNum = (number: number, titles: PluralizeTitles): string => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};

const titles: Record<PluralizeType, PluralizeTitles> = {
  age: ["год", "года", "лет"],
};

export const pluralize = (type: PluralizeType, value: number): string => {
  return `${value} ${declOfNum(value, titles[type])}`;
};
