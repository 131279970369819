import { RoomState } from "backend/src/common/types/roomState";
import { getOrCreateSocket } from "socket/socket";
import { store } from "store/store";

export const startGame = () => {
  const socket = getOrCreateSocket();
  if (store.currentRoom && store.roomState !== RoomState.playing) {
    socket.emit("startGame", store.currentRoom);
  }
};
