import {
  AnimalCard,
  AnimalContext,
  ExtensionContext,
  PlayerContext,
  RoomContext,
  SimplifiedExtensionCard,
} from '../../common/types/ExtensionCard';
import { Player, Room } from '../../common/types/roomState';

type PlayerContextTarget = {
  player: Player;
};

type AnimalContextTarget = {
  animal: AnimalCard;
};

type ExtensionContextTarget = {
  extension: SimplifiedExtensionCard;
};

function getContext(room: Room, target: undefined): RoomContext;
function getContext(room: Room, target: PlayerContextTarget): PlayerContext;
function getContext(room: Room, target: AnimalContextTarget): AnimalContext;
function getContext(
  room: Room,
  target: ExtensionContextTarget,
): ExtensionContext;

function getContext(room: Room, target: unknown) {
  const extensionContextTarget = target as ExtensionContextTarget;
  const animalContextTarget = target as AnimalContextTarget;
  const playerContextTarget = target as PlayerContextTarget;

  // Find animal parent and form extension context
  if (extensionContextTarget.extension) {
    let parentAnimal: AnimalCard;
    room.players.some((player) =>
      player.cards.some((animal) =>
        animal.extensions.some((extension) => {
          if (extension === extensionContextTarget.extension) {
            parentAnimal = animal;
            return true;
          }
          return false;
        }),
      ),
    );
    // @ts-ignore
    if (!parentAnimal) {
      throw new Error('Animal by extension not found');
    }
    const animalContext: AnimalContext = getContext(room, {
      animal: parentAnimal,
    });
    return {
      ...animalContext,
      extension: extensionContextTarget.extension,
    };
  }

  // Find player parent and form animal context
  if (animalContextTarget.animal) {
    let parentPlayer: Player;
    room.players.some((player) =>
      player.cards.some((animal) => {
        if (animal === animalContextTarget.animal) {
          parentPlayer = player;
          return true;
        }
        return false;
      }),
    );
    // @ts-ignore
    if (!parentPlayer) {
      throw new Error('Player by animal not found');
    }
    const playerContext: PlayerContext = getContext(room, {
      player: parentPlayer,
    });
    return {
      ...playerContext,
      animal: animalContextTarget.animal,
    };
  }

  if (playerContextTarget.player) {
    return {
      room,
      player: playerContextTarget.player,
    };
  }

  return {
    room,
  };
}

export default getContext;
