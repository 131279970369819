import { ClientToServerEvents } from "backend/src/common/types/socket";
import {
  Box,
  Button,
  Container,
  Form,
  Heading,
  Section,
} from "react-bulma-components";
import { toast } from "react-toastify";
import { getOrCreateSocket } from "socket/socket";

type RoomPageProps = {
  room: string;
};

const actions: Array<{
  action: keyof ClientToServerEvents;
  label: string;
}> = [
  {
    action: "oneStepBack",
    label: "На один ход назад",
  },
  {
    action: "toStageStart",
    label: "К началу фазы",
  },
  {
    action: "toCycleStart",
    label: "К началу цикла",
  },
];

export const RoomPage: React.FC<RoomPageProps> = ({ room }) => {
  return (
    <Section>
      <Container>
        <Box>
          <Heading>{room}</Heading>
          {actions.map((action) => (
            <Form.Field key={action.action}>
              <Button
                onClick={() => {
                  const socket = getOrCreateSocket();
                  socket.emit(action.action, room);
                  toast(`Запрос на действие "${action.label}" отправлен`);
                }}
              >
                {action.label}
              </Button>
            </Form.Field>
          ))}
        </Box>
      </Container>
    </Section>
  );
};
