import { GameStage } from "backend/src/common/types/roomState";
import { Animals } from "components/Animals/Animals";
import { Deck } from "components/Deck/Deck";
import { FoodBase } from "components/FoodBase/FoodBase";
import { ReadyButton } from "components/ReadyButton/ReadyButton";
import { observer } from "mobx-react-lite";
import { PlayerModelType } from "store/models/Player";
import { store } from "store/store";
import GridLayout, { Layout } from "react-grid-layout";

import styles from "./styles.module.scss";
import { useRef, useEffect, useCallback } from "react";
import { useSize } from "hooks/useSize";
import { useStoredState } from "hooks/useStoredState";
import { Header } from "components/Header/Header";
import { SplashScreen } from "components/SplashScreen/SplashScreen";
import { STAGES_NAMES } from "backend/src/common/gameData/stages";
import { Form, Tag } from "react-bulma-components";
import { declOfNum } from "backend/src/common/utils/declOfNum";

const DEFAULT_PLAYER_LAYOUT: Pick<Layout, "h" | "w" | "minH" | "minW"> = {
  w: 10,
  h: 5,
  minH: 3,
  minW: 3,
};

const DEFAULT_DECK_LAYOUT = {
  i: "self-deck",
  x: 0,
  y: 14,
  w: 20,
  h: 6,
  minH: 2,
  minW: 5,
};

const DEFAULT_LAYOUT: Layout[] = [
  { ...DEFAULT_PLAYER_LAYOUT, i: "self-animals", x: 0, y: 9, w: 20, h: 6 },
  DEFAULT_DECK_LAYOUT,
];
const DEFAULT_LAYOUT_FOR_PLACING: Layout[] = [
  { ...DEFAULT_PLAYER_LAYOUT, i: "self-animals", x: 10, y: 14, w: 10, h: 6 },
  { ...DEFAULT_DECK_LAYOUT, w: 10 },
];
const ROWS_COUNT = 20;
const COLS_COUNT = 20;
const DEFAULT_ROWS_FOR_OTHERS = ROWS_COUNT - DEFAULT_DECK_LAYOUT.h;
const EATING_ROWS_FOR_OTHERS = DEFAULT_ROWS_FOR_OTHERS;

export const CurrentPlayer = observer(() => {
  const placing = store.gameState.stage === GameStage.placing;
  const boardRef = useRef<HTMLDivElement>(null);
  const boardSize = useSize(boardRef);
  const rowHeight = (boardSize.height - ROWS_COUNT * 10) / ROWS_COUNT;
  const otherPlayersCount = store.otherPlayers.length;
  const mode = placing ? "placing" : "eating";
  const [wasLayoutChanged, setWasLayoutChanged] = useStoredState<{
    [key in typeof mode]?: boolean;
  }>(`wasLayoutChanged-${store.currentRoom}`, {});
  const [layoutForPlacing, setLayoutForPlacing] = useStoredState<Layout[]>(
    `layoutForPlacing-${store.currentRoom}`,
    DEFAULT_LAYOUT_FOR_PLACING,
  );
  const [layoutForEating, setLayoutForEating] = useStoredState<Layout[]>(
    `layoutForEating-${store.currentRoom}`,
    DEFAULT_LAYOUT,
  );
  const layout = placing ? layoutForPlacing : layoutForEating;
  const setLayout = placing ? setLayoutForPlacing : setLayoutForEating;
  useEffect(() => {
    if (placing && !layout.some((l) => l.i === DEFAULT_DECK_LAYOUT.i)) {
      setLayout([...layout, DEFAULT_DECK_LAYOUT]);
    }
  }, [layout, placing, setLayout]);
  const resetLayout = useCallback(() => {
    const columns = Math.ceil(Math.sqrt(otherPlayersCount));
    const rows = Math.ceil(otherPlayersCount / columns);
    const rowsForOthers = placing
      ? DEFAULT_ROWS_FOR_OTHERS
      : EATING_ROWS_FOR_OTHERS;
    const rowsPerRow = Math.floor(rowsForOthers / rows);
    const colsPerColumn = Math.floor(COLS_COUNT / columns);
    const playersLayouts: Layout[] = [];
    let playersAdded = 0;
    store.players.forEach((player, playerIndex) => {
      if (player === store.currentPlayer) {
        return;
      }
      const column = playersAdded % columns;
      const row = Math.floor(playersAdded / columns);

      playersLayouts.push({
        ...DEFAULT_PLAYER_LAYOUT,
        x: colsPerColumn * column,
        y: rowsPerRow * row,
        h: rowsPerRow,
        w: colsPerColumn,
        i: `player-${playerIndex}`,
      });

      playersAdded++;
    });
    const defaultLayout = placing ? DEFAULT_LAYOUT_FOR_PLACING : DEFAULT_LAYOUT;
    const newLayouts = [...defaultLayout, ...playersLayouts];
    setLayout(newLayouts);
  }, [otherPlayersCount, placing, setLayout]);
  useEffect(() => {
    if (!wasLayoutChanged[mode]) {
      resetLayout();
    }
  }, [resetLayout, wasLayoutChanged, mode]);
  const stepsLeft = store.gameState.cardsPerStepsAverage
    ? Math.round(
        store.gameState.cardsLeft / store.gameState.cardsPerStepsAverage,
      )
    : 0;
  const timeLeft =
    stepsLeft && store.gameState.timePerStepsAverage
      ? Math.round(
          (stepsLeft * store.gameState.timePerStepsAverage) / 1000 / 60,
        )
      : null;
  const additionalInfo = [
    <Form.Control key="stage">
      <Tag.Group hasAddons>
        <Tag color="dark">Стадия</Tag>
        <Tag>{STAGES_NAMES[store.gameState.stage]}</Tag>
      </Tag.Group>
    </Form.Control>,
    store.activeUser && (
      <Form.Control key="active">
        <Tag.Group hasAddons>
          <Tag color="dark">Ходит</Tag>
          <Tag>{store.activeUser.meta.name}</Tag>
        </Tag.Group>
      </Form.Control>
    ),
    store.gameState.cardsLeft <= 0 && (
      <Tag key="last" color="danger">
        Последний ход
      </Tag>
    ),
    store.gameState.cardsPerStepsAverage && store.gameState.cardsLeft > 0 && (
      <Form.Control key="rest">
        <Tag.Group hasAddons>
          <Tag color="dark">
            {declOfNum(stepsLeft, ["Остался", "Осталось", "Осталось"])}
          </Tag>
          <Tag>
            ~{stepsLeft} {declOfNum(stepsLeft, ["ход", "хода", "ходов"])}
          </Tag>
          {timeLeft !== null && (
            <Tag>
              ~{timeLeft} {declOfNum(timeLeft, ["минута", "минуты", "минут"])}
            </Tag>
          )}
        </Tag.Group>
      </Form.Control>
    ),
  ].filter(Boolean);
  return (
    <>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          left: 10,
          fontSize: "0.6em",
          opacity: 0.5,
        }}
      >
        {store.actions}
      </div>
      <Header
        withFullScreenButton
        withLayoutChangeButton
        withDevtools
        onResetLayout={() => {
          store.setIsEditingLayout(false);
          setWasLayoutChanged({ ...wasLayoutChanged, [mode]: false });
          resetLayout();
        }}
        title={store.currentRoom}
        additionalInfo={<Form.Field kind="group">{additionalInfo}</Form.Field>}
      />
      <div className={styles.board}>
        {store.gameState.stage === GameStage.eating && (
          <div className={styles.foodContainer}>
            <FoodBase />
          </div>
        )}
        <div className={styles.rest} ref={boardRef}>
          {boardSize.width && (
            <GridLayout
              isDraggable={store.isEditingLayout}
              isResizable={store.isEditingLayout}
              layout={layout}
              rowHeight={rowHeight}
              maxRows={ROWS_COUNT}
              width={boardSize.width}
              cols={COLS_COUNT}
              autoSize
              onLayoutChange={(newLayout) => {
                setLayout(newLayout);
                if (store.isEditingLayout) {
                  setWasLayoutChanged({ ...wasLayoutChanged, [mode]: true });
                }
              }}
            >
              {store.players.map((player, playerIndex) => {
                if (player === store.currentPlayer) {
                  return null;
                }
                return (
                  <div key={`player-${playerIndex}`}>
                    <Animals other player={player} playerIndex={playerIndex} />
                  </div>
                );
              })}
              <div key="self-animals">
                <Animals
                  player={store.currentPlayer as PlayerModelType}
                  playerIndex={store.currentPlayerIndex}
                />
              </div>
              {placing && (
                <div key="self-deck">
                  <Deck />
                </div>
              )}
            </GridLayout>
          )}
        </div>
        {!store.isEditingLayout && (
          <>
            <ReadyButton />
            <SplashScreen />
          </>
        )}
      </div>
    </>
  );
});
