import { Header } from "components/Header/Header";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  Container,
  Message,
  Section,
  Table,
} from "react-bulma-components";
import { joinRoom } from "socket/events/joinRoom";
import { store } from "store/store";
import { getRandomRoomName } from "utils/getRandomRoomName";

export const Results = observer(() => {
  return (
    <>
      <Header
        title={store.currentRoom}
        onBack={() => {
          store.setCurrentRoom(null);
        }}
      />
      <Section>
        <Container>
          <Box>
            <Table.Container>
              <Table size="fullwidth">
                <thead>
                  <tr>
                    <th>Игрок</th>
                    <th>Счет</th>
                  </tr>
                </thead>
                <tbody>
                  {store.gameState.results.map((result) => {
                    const player = store.players.find(
                      (p) => p.id === result.playerId,
                    );
                    return (
                      <tr key={result.playerId}>
                        <td>{player?.meta.name}</td>
                        <td>{result.score}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Table.Container>
            {store.gameState.additionalResults.length > 0 && (
              <Message>
                <Message.Body>
                  Дополнительные результаты:
                  {store.gameState.additionalResults.map((result, index) => {
                    return <div key={index}>{result}</div>;
                  })}
                </Message.Body>
              </Message>
            )}
            <Button
              color="primary"
              fullwidth
              onClick={() => {
                joinRoom(getRandomRoomName());
              }}
            >
              Начать новую игру
            </Button>
          </Box>
        </Container>
      </Section>
    </>
  );
});
