import styles from "./styles.module.scss";

import { AnimalCardModelType } from "store/models/AnimalCard";
import ReactDOM from "react-dom";
import { portalTarget } from "./ModalPortal";
import { AnimalCardSimple } from "components/AnimalCard/AnimalCardSimple";
import { ExtensionCardSimple } from "components/ExtensionCard/ExtensionCardSimple";
import { getVisibleExtensions } from "backend/src/common/utils/getVisibleExtensions";
import { ExtensionCardModelType } from "store/models/ExtensionCard";
import { isFull } from "backend/src/common/utils/isFull";
import { getAnimalContext } from "utils/context/getAnimalContext";

type AnimalModalProps = {
  card: AnimalCardModelType;
  index: number;
  playerIndex: number;
  other: boolean;
  onClose: () => void;
};

export const AnimalModal: React.FC<AnimalModalProps> = ({
  card,
  index: animalIndex,
  onClose,
  other,
}) => {
  const full = isFull(getAnimalContext(card));
  const extensions = getVisibleExtensions(
    card.extensions as ExtensionCardModelType[],
    !!other,
  );
  const optimalColumnsCount = Math.ceil(Math.sqrt(extensions.length)) + 1;
  return ReactDOM.createPortal(
    <div
      className={styles.modal}
      onMouseOver={(e) => e.stopPropagation()}
      onClick={(e) => {
        e.stopPropagation();
        onClose();
      }}
    >
      <div
        className={styles.cardContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.card}>
          <AnimalCardSimple index={animalIndex} other={other} card={card} />
        </div>
      </div>
      <div
        className={styles.extensionsContainer}
        style={{ gridTemplateColumns: `repeat(${optimalColumnsCount}, 1fr)` }}
      >
        {extensions.map((extension, index) => (
          <div onClick={(e) => e.stopPropagation()} key={index}>
            <ExtensionCardSimple
              card={extension}
              extensionIndex={index}
              animalIndex={animalIndex}
              other={other}
              isFull={full}
              onUse={() => onClose()}
            />
          </div>
        ))}
      </div>
    </div>,
    portalTarget.current as HTMLDivElement,
  );
};
