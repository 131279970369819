import { Header } from "components/Header/Header";
import { NameInput } from "components/NameInput/NameInput";
import { CustomModal } from "components/WaitingRoom/Modals/CustomModal";
import { CustomModalps } from "components/WaitingRoom/Modals/CustomModalps";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShuffle,
  faPlus,
  faRobot,
  faMinus,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import {
  Box,
  Icon,
  Container,
  Block,
  Section,
  Button,
  Heading,
  Form,
} from "react-bulma-components";
import { addBot } from "socket/events/addBot";
import { leaveRoom } from "socket/events/leaveRoom";
import { getPlayerAvatar } from "utils/getPlayerAvatar";
import { randomizeAvatar } from "socket/events/randomizeAvatar";
import { removeBot } from "socket/events/removeBot";
import { startGame } from "socket/events/startGame";
import { store } from "store/store";
import styles from "./styles.module.scss";

const getCopyCode = () => {
  toast("Ссылка скопирована!");
  navigator.clipboard.writeText(
    "https://playevolution.me/#" + store.currentRoom,
  );
};

export const WaitingRoom = observer(() => {
  const room = store.currentRoom;
  useEffect(() => {
    window.location.hash = room;
  }, [room]);
  const [modalpsActive, setModalpsActive] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const isHost = store.players[0] === store.currentPlayer;
  const currentPlayer = store.currentPlayer;
  if (!currentPlayer) return null;

  return (
    <div>
      <Header
        title={store.currentRoom}
        onBack={() => leaveRoom(store.currentRoom)}
      />
      <hr />

      <Section>
        <Container>
          <Box>
            <NameInput />

            <Form.Field>
              <Form.Label>Аватар</Form.Label>
              <Container
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  className={styles.image}
                  src={getPlayerAvatar(currentPlayer)}
                />
                <Form.Control>
                  <Button
                    color="primary"
                    onClick={() => {
                      randomizeAvatar();
                    }}
                  >
                    <Icon>
                      <FontAwesomeIcon icon={faShuffle} />
                    </Icon>
                  </Button>
                </Form.Control>
              </Container>
            </Form.Field>

            <div className={styles.container_settings}>
              <Button.Group align="center">
                <Button onClick={() => setModalActive(true)}>
                  Настройки игры
                </Button>
                <Button onClick={() => setModalpsActive(true)}>
                  Личные настройки
                </Button>
              </Button.Group>
              <CustomModal active={modalActive} setActive={setModalActive} />
              <CustomModalps
                activeps={modalpsActive}
                setActiveps={setModalpsActive}
              />
              {isHost && (
                <Button
                  onClick={startGame}
                  color="primary"
                  renderAs="span"
                  className={styles.button_start}
                >
                  Начать игру
                </Button>
              )}
            </div>
          </Box>
        </Container>
      </Section>
      <Container>
        <Box>
          <Block>
            <Heading subtitle>Игроки: </Heading>
            {store.players.map((player) => (
              <li key={player.id}>
                {player.isBot && "🤖 "}
                {player.meta.name || "Без имени"}{" "}
                {player === store.currentPlayer && "(Вы)"}
              </li>
            ))}
          </Block>
          <Block
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",
            }}
          >
            {isHost && (
              <>
                <div className={styles.margin}>
                  <Icon
                    color="primary"
                    onClick={getCopyCode}
                    className={styles.plus_minus}
                  >
                    <FontAwesomeIcon
                      className="fas fa-home fa-xl"
                      icon={faShare}
                    />
                  </Icon>
                </div>
                <div className={styles.margin}>
                  <Icon
                    color="primary"
                    onClick={() => {
                      removeBot();
                    }}
                    className={styles.plus_minus}
                  >
                    <FontAwesomeIcon
                      className="fas fa-home fa-xl"
                      icon={faMinus}
                    />
                  </Icon>
                </div>
                <div className={styles.margin}>
                  <Icon color="primary">
                    <FontAwesomeIcon
                      className="fas fa-home fa-xl"
                      icon={faRobot}
                    />
                  </Icon>
                </div>
                <div className={styles.margin}>
                  <Icon
                    color="primary"
                    onClick={() => {
                      addBot();
                    }}
                    className={styles.plus_minus}
                  >
                    <FontAwesomeIcon
                      className="fas fa-home fa-xl"
                      icon={faPlus}
                    />
                  </Icon>
                </div>
              </>
            )}
          </Block>
        </Box>
      </Container>

      <hr />
    </div>
  );
});
