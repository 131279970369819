import { PlayerModelType } from "store/models/Player";
import { store } from "store/store";
import { AvatarGenerator } from "random-avatar-generator";

const generator = new AvatarGenerator();

export const getPlayerAvatar = (player: PlayerModelType): string => {
  const hash = player.avatarHash || [store.currentRoom, player.id].join("-");
  return generator.generateRandomAvatar(hash);
};
