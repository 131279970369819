import { Navbar } from "react-bulma-components";
import styles from "./styles.module.scss";
import lizard from "resources/lizardWhite.svg";
import { store } from "store/store";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { Devtools } from "./Devtools/Devtools";
import { Link, Route, Switch } from "wouter";
import { useStoredState } from "hooks/useStoredState";
import classNames from "classnames";

type HeaderProps = {
  title?: string;
  additionalInfo?: React.ReactNode;
  onBack?: () => void;
  onResetLayout?: () => void;
  withFullScreenButton?: boolean;
  withLayoutChangeButton?: boolean;
  withDevtools?: boolean;
  centered?: boolean;
};

export const Header: React.FC<HeaderProps> = observer(
  ({
    title = "Evolution",
    additionalInfo,
    onBack,
    onResetLayout,
    withFullScreenButton,
    withLayoutChangeButton,
    withDevtools,
    centered,
  }) => {
    const [clicksCount, setClicksCount] = useState(0);
    const [adminMode, setAdminMode] = useStoredState("adminMode", false);
    useEffect(() => {
      if (clicksCount > 10) {
        setAdminMode(true);
      }
      if (clicksCount > 0) {
        const timeout = setTimeout(() => {
          setClicksCount(0);
        }, 15000);
        return () => clearTimeout(timeout);
      }
    }, [clicksCount, setAdminMode]);
    const [isMenuActive, setIsMenuActive] = useState(false);
    const isFullScreenSupported = useMemo(() => {
      return (
        document.fullscreenEnabled ||
        // @ts-ignore
        document.webkitFullscreenEnabled ||
        // @ts-ignore
        document.mozFullScreenEnabled ||
        // @ts-ignore
        document.msFullscreenEnabled
      );
    }, []);
    return (
      <Navbar
        color="primary"
        active={isMenuActive}
        onClick={() => {
          setClicksCount(clicksCount + 1);
        }}
        className={classNames({
          [styles.centered]: centered,
        })}
      >
        <Navbar.Brand>
          <Navbar.Item href="/">
            <div className={styles.logoContainer}>
              <img className={styles.logo} src={lizard} />
              <div className={styles.evolutionSign}>{title}</div>
            </div>
          </Navbar.Item>
          {!centered && (
            <Navbar.Burger onClick={() => setIsMenuActive(!isMenuActive)} />
          )}
        </Navbar.Brand>
        <Navbar.Menu>
          <Navbar.Container align="left">
            {additionalInfo && (
              <>
                <Navbar.Divider />
                <Navbar.Item renderAs="div" hoverable={false}>
                  {additionalInfo}
                </Navbar.Item>
              </>
            )}
          </Navbar.Container>
          <Navbar.Container align="right">
            {onBack && (
              <Navbar.Item onClick={onBack}>
                <span>Выйти</span>
              </Navbar.Item>
            )}
            {withLayoutChangeButton && (
              <Navbar.Item
                onClick={() => {
                  store.setIsEditingLayout(!store.isEditingLayout);
                }}
              >
                {store.isEditingLayout ? "Сохранить" : "Изменить раскладку"}
              </Navbar.Item>
            )}
            {withLayoutChangeButton &&
              !!onResetLayout &&
              store.isEditingLayout && (
                <Navbar.Item onClick={onResetLayout}>Сбросить</Navbar.Item>
              )}
            {withFullScreenButton &&
              !store.isEditingLayout &&
              isFullScreenSupported && (
                <Navbar.Item
                  onClick={() => {
                    if (document.fullscreenElement) {
                      document.exitFullscreen();
                    } else {
                      document.documentElement.requestFullscreen();
                    }
                  }}
                >
                  <span>Полный экран</span>
                </Navbar.Item>
              )}
            {withDevtools && adminMode && (
              <Switch>
                <Route path="/">
                  <Navbar.Item href="/admin" renderAs={Link}>
                    Admin
                  </Navbar.Item>
                </Route>
                <Route path="/admin">
                  <Navbar.Item href="/" renderAs={Link}>
                    Game
                  </Navbar.Item>
                </Route>
              </Switch>
            )}
            {withDevtools && !store.isEditingLayout && adminMode && (
              <Devtools />
            )}
          </Navbar.Container>
        </Navbar.Menu>
      </Navbar>
    );
  },
);
