import { ExtensionType } from "backend/src/common/types/ExtensionCard";
import { ExtensionCardSimple } from "components/ExtensionCard/ExtensionCardSimple";
import { useEffect, useRef, useState } from "react";
import { useWindowSize, useRaf } from "rooks";
import { store } from "store/store";

import styles from "./styles.module.scss";

const CARD_WIDTH = 145;
const CARD_MOVE_TIME = 15;

const availableTypes = Object.values(ExtensionType);
const getRandomCard = (key: number) => {
  const type =
    availableTypes[Math.floor(Math.random() * availableTypes.length)];
  return (
    <ExtensionCardSimple
      other
      key={key}
      animalIndex={0}
      extensionIndex={0}
      // @ts-ignore
      card={{
        type,
        visibleType: type,
        id: Math.random().toString(),
        animal: {
          // @ts-ignore
          id: Math.random().toString(),
          extensions: [],
          owner: store.currentPlayer,
        },
      }}
    />
  );
};

type BackgroundRowProps = {
  gap: number;
  direction: "left" | "right";
};

export const BackgroundRow: React.FC<BackgroundRowProps> = ({
  gap,
  direction,
}) => {
  const cardWidthWithGap = CARD_WIDTH + gap;
  const { outerWidth } = useWindowSize();
  const cardsCount = Math.ceil(outerWidth / cardWidthWithGap) + 1;
  const [cards, setCards] = useState(() => {
    return Array.from({ length: cardsCount }, (_, i) => i).map(getRandomCard);
  });
  useEffect(() => {
    if (cards.length < cardsCount) {
      setCards((oldCards) => {
        return [
          ...oldCards,
          ...Array.from({ length: cardsCount - cards.length }, getRandomCard),
        ];
      });
    }
    if (cards.length > cardsCount) {
      setCards((oldCards) => {
        return oldCards.slice(0, cardsCount);
      });
    }
  }, [cards.length, cardsCount]);
  const [offset, setOffset] = useState(0);
  const startTime = useRef(Date.now());
  const previousOffset = useRef(offset);
  const cardsIndex = useRef(cards.length);
  useRaf(() => {
    const time = Date.now() - startTime.current;
    const seconds = time / 1000;
    const newOffset = (seconds % CARD_MOVE_TIME) / CARD_MOVE_TIME;
    if (previousOffset !== null && newOffset < previousOffset.current) {
      setCards((oldCards) => {
        cardsIndex.current += 1;
        if (direction === "left") {
          const [, ...newCards] = oldCards;
          newCards.push(getRandomCard(cardsIndex.current));
          return newCards;
        } else {
          const newCards = [...oldCards];
          newCards.pop();
          newCards.unshift(getRandomCard(cardsIndex.current));
          return newCards;
        }
      });
    }
    previousOffset.current = newOffset;
    setOffset(newOffset);
  }, true);
  return (
    <div
      className={styles.row}
      style={{
        gap,
        left: direction === "left" ? 0 : -cardWidthWithGap,
        transform: `translateX(${direction === "left" ? "-" : ""}${
          offset * cardWidthWithGap
        }px)`,
      }}
    >
      {cards}
    </div>
  );
};
