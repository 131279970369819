import { Header } from "components/Header/Header";
import { observer } from "mobx-react-lite";
import { store } from "store/store";
import { Redirect } from "wouter";
import { RoomPage } from "./RoomPage";

export const AdminPage = observer(() => {
  const currentRoom = store.currentRoom;
  if (!currentRoom) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <Header />
      <RoomPage room={currentRoom} />
    </div>
  );
});
