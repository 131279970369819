import {
  GameStage,
  RoomState,
  SimpleRoom,
} from "backend/src/common/types/roomState";
import { cast, clone, types } from "mobx-state-tree";
import { getOrCreateMyId } from "utils/getOrCreateMyId";
import { AnimalCardModel, AnimalCardModelType } from "./AnimalCard";
import { ExtensionCardModel } from "./ExtensionCard";
import { GameStateModel } from "./GameState";
import { PlayerModelType, PlayerModel } from "./Player";
import { SettingsModel } from "./Settings";

export const StoreModel = types
  .model({
    players: types.array(PlayerModel),
    commonExtensions: types.optional(types.array(ExtensionCardModel), []),
    gameState: GameStateModel,
    currentRoom: types.optional(types.string, ""),
    roomState: types.optional(
      types.enumeration<RoomState>(Object.values(RoomState)),
      RoomState.pending,
    ),
    settings: SettingsModel,
    draggingAnimal: types.maybeNull(AnimalCardModel),
    draggingApple: types.optional(types.boolean, false),
    draggingSomething: types.optional(types.boolean, false),
    isEditingLayout: types.optional(types.boolean, false),
    actions: types.optional(types.number, 0),
    openRooms: types.optional(
      types.array(
        types.model({
          name: types.string,
          playersCount: types.number,
        }),
      ),
      [],
    ),
    isPublic: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setOpenRooms(rooms: SimpleRoom[]) {
      self.openRooms = cast(
        rooms.map((room) => ({
          name: room.name,
          playersCount: room.playersCount,
        })),
      );
    },
    setActions(actions: number) {
      self.actions = actions;
    },
    setDraggingApple(draggingApple: boolean) {
      self.draggingApple = draggingApple;
    },
    setIsEditingLayout(isEditingLayout: boolean) {
      self.isEditingLayout = isEditingLayout;
    },
    setRoomState: (state: RoomState) => {
      self.roomState = state;
    },
    setCurrentRoom: (roomName: string | null) => {
      self.currentRoom = roomName || "";
      if (!roomName) {
        self.players.length = 0;
      }
    },
    setDraggingAnimal: (animal: AnimalCardModelType | null) => {
      self.draggingAnimal = animal ? clone(animal) : null;
    },
    setDraggingSomething: (extension: boolean) => {
      self.draggingSomething = extension;
    },
  }))
  .views((self) => ({
    get currentPlayer(): PlayerModelType | null {
      return (
        self.players.find(
          (player: PlayerModelType) => player.id === getOrCreateMyId(),
        ) || null
      );
    },
    get activeUser(): PlayerModelType | null {
      return (
        self.players.find(
          (player: PlayerModelType) => self.gameState.activeUser === player.id,
        ) || null
      );
    },
    get currentPlayerIndex(): number {
      return self.players.findIndex(
        (player: PlayerModelType) => player.id === getOrCreateMyId(),
      );
    },
    get otherPlayers(): PlayerModelType[] {
      return self.players.filter(
        (player: PlayerModelType) => player.id !== getOrCreateMyId(),
      );
    },
    get iAmActiveUser(): boolean {
      return ["*", getOrCreateMyId()].includes(self.gameState.activeUser);
    },
    get iAmReady(): boolean {
      return self.gameState.ready.includes(getOrCreateMyId());
    },
    get isPlacing(): boolean {
      return self.gameState.stage === GameStage.placing && !this.iAmReady;
    },
  }));
