import { getParent, Instance, types } from "mobx-state-tree";
import {
  ExtensionType,
  SimplifiedExtensionCard,
} from "backend/src/common/types/ExtensionCard";
import { store } from "store/store";
import { GameState } from "backend/src/common/types/roomState";
import { canExtensionBeUsed } from "backend/src/common/utils/canExtensionBeUsed";
import { extensionCards } from "backend/src/common/gameData/extensions";

export const ExtensionCardModel = types
  .model({
    id: types.optional(types.string, ""),
    type: types.enumeration<ExtensionType>(Object.values(ExtensionType)),
    wasUsedInCurrentStage: types.optional(types.boolean, false),
    wasUsedInPreviousStage: types.optional(types.boolean, false),
    wasUsedInCurrentTick: types.optional(types.boolean, false),
    wasDisclosed: types.optional(types.boolean, false),
    isDisabledByTumor: types.optional(types.boolean, false),
    wasPlacedByUser: types.optional(types.string, ""),
    pairWithAnimal: types.optional(types.string, ""),
    data: types.optional(
      types.model({
        storedFood: types.optional(types.number, 0),
        stepsToBeBorn: types.optional(types.number, 0),
      }),
      {},
    ),
  })
  .views((self) => ({
    get canBeUsed(): boolean {
      return (
        store.iAmActiveUser &&
        canExtensionBeUsed(
          store.gameState as GameState,
          self as SimplifiedExtensionCard,
        )
      );
    },
    get animal() {
      // AnimalCardModelType
      return getParent(self, 2);
    },
    get visibleType() {
      // @ts-ignore
      if (this.animal?.owner === store.currentPlayer) {
        return self.type;
      }
      const data = extensionCards[self.type];
      if (data.mimics) {
        return data.mimics;
      }
      return self.type;
    },
  }));

export type ExtensionCardModelType = Instance<typeof ExtensionCardModel>;
